import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";

const WholeBankSettlement = () => {
  const [data2AJIO, setData2AJIO] = useState([]);
  const [errorAJIOContentAJIO, setErrorAJIOContentAJIO] = useState([]);
  const [input2AJIO, setInput2AJIO] = useState();
  const [errorAJIO, setErrorAJIO] = useState(false);
  const [salesAJIO, setSalesAJIO] = useState([]);
  const [returnsAJIO, setReturnsAJIO] = useState([]);
  const [resultAJIO, setResultAJIO] = useState([]);
  const [resultAJIO2, setResultAJIO2] = useState([]);

  const [data2AMAZON, setData2AMAZON] = useState([]);
  const [errorAMAZONContentAMAZON, setErrorAMAZONContentAMAZON] = useState([]);
  const [input2AMAZON, setInput2AMAZON] = useState();
  const [errorAMAZON, setErrorAMAZON] = useState(false);
  const [jsonDataAMAZON, setJsonDataAMAZON] = useState([]);
  const [resultAMAZON, setResultAMAZON] = useState([]);
  const [resultAMAZON2, setResultAMAZON2] = useState([]);

  const [data2FLIPKART, setData2FLIPKART] = useState([]);
  const [errorFLIPKARTContentFLIPKART, setErrorFLIPKARTContentFLIPKART] =
    useState([]);
  const [input2FLIPKART, setInput2FLIPKART] = useState();
  const [errorFLIPKART, setErrorFLIPKART] = useState(false);
  const [jsonDataFLIPKART, setJsonDataFLIPKART] = useState([]);
  const [resultFLIPKART, setResultFLIPKART] = useState([]);
  const [resultFLIPKART2, setResultFLIPKART2] = useState([]);
  const [sheetnamesFLIPKART, setSheetnamesFLIPKART] = useState([]);

  const [data2MYNTRA, setData2MYNTRA] = useState([]);
  const [errorMYNTRAContentMYNTRA, setErrorMYNTRAContentMYNTRA] = useState([]);
  const [input2MYNTRA, setInput2MYNTRA] = useState();
  const [errorMYNTRA, setErrorMYNTRA] = useState(false);
  const [salesMYNTRA, setSalesMYNTRA] = useState([]);
  const [returnsMYNTRA, setReturnsMYNTRA] = useState([]);
  const [resultMYNTRA, setResultMYNTRA] = useState([]);
  const [resultMYNTRA2, setResultMYNTRA2] = useState([]);

  const [data2MYNTRASJIT, setData2MYNTRASJIT] = useState([]);
  const [errorMYNTRAContentMYNTRASJIT, setErrorMYNTRAContentMYNTRASJIT] = useState([]);
  const [input2MYNTRASJIT, setInput2MYNTRASJIT] = useState();
  const [errorMYNTRASJIT, setErrorMYNTRASJIT] = useState(false);
  const [salesMYNTRASJIT, setSalesMYNTRASJIT] = useState([]);
  const [returnsMYNTRASJIT, setReturnsMYNTRASJIT] = useState([]);
  const [resultMYNTRASJIT, setResultMYNTRASJIT] = useState([]);
  const [resultMYNTRA2SJIT, setResultMYNTRA2SJIT] = useState([]);
  console.log(salesAJIO);
  console.log(returnsAJIO);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  /////////////////////////////////////////////////////////////////////////////////////////////FUNCTION CALLS
  const RunAll = (e) => {
    if (data2AJIO.length > 0) {
      handleFormSubmitAJIO(e);
    }
    if (data2AMAZON.length > 0) {
      handleFormSubmitAMAZON(e);
    }
    if (data2FLIPKART.length > 0) {
      handleFormSubmitFLIPKART(e);
    }
    if (data2MYNTRA.length > 0) {
      handleFormSubmitMYNTRA(e);
    }
    if (data2MYNTRASJIT.length > 0) {
      handleFormSubmitMYNTRASJIT(e);
    }

  };
  /////////////////////////////////////////////////////////////////////////////////////////////DOWNLOAD FILES
  const DownloadFiles = async () => {
    try {
      await Promise.all([DownloadFirstFile(), DownloadSecoundFile()]);
      // Optional: Add success feedback
      console.log("All files downloaded successfully");
    } catch (error) {
      // Handle any errors that occur during downloads
      console.error("Error downloading files:", error);
      // Optional: Add user feedback for errors
    }
  };
  //////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////DOWNLOAD FIRST FILE
  const DownloadFirstFile = async () => {
    const workbook = new ExcelJS.Workbook();

    if (data2MYNTRA.length > 0) {
      const worksheet4 = workbook.addWorksheet("MYNTRA");

      // Add a row with the title "Payment Receipt Information"
      worksheet4.addRow(["Payment Receipt Information"]);

      // Get the last row where the title was added
      const lastRow4 = worksheet4.getRow(worksheet4.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet4.mergeCells(worksheet4.rowCount, 1, worksheet4.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow4 = worksheet4.addRow(Object.keys(resultMYNTRA[0]));
      headerRow4.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });

      resultMYNTRA.forEach((data, index) => {
        worksheet4.addRow(Object.values(data)); // Add regular data row
      });
    }
    if (data2MYNTRASJIT.length > 0) {
      const worksheet4 = workbook.addWorksheet("MYNTRA SJIT");

      // Add a row with the title "Payment Receipt Information"
      worksheet4.addRow(["Payment Receipt Information"]);

      // Get the last row where the title was added
      const lastRow4 = worksheet4.getRow(worksheet4.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet4.mergeCells(worksheet4.rowCount, 1, worksheet4.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow4 = worksheet4.addRow(Object.keys(resultMYNTRASJIT[0]));
      headerRow4.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });

      resultMYNTRASJIT.forEach((data, index) => {
        worksheet4.addRow(Object.values(data)); // Add regular data row
      });
    }
    if (data2FLIPKART.length > 0) {
      const worksheet3 = workbook.addWorksheet("FLIPKART");
      // Add a row with the title "Payment Receipt Information"
      worksheet3.addRow(["Payment Receipt Information"]);

      // Get the last row where the title was added
      const lastRow3 = worksheet3.getRow(worksheet3.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet3.mergeCells(worksheet3.rowCount, 1, worksheet3.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow3 = worksheet3.addRow(Object.keys(resultFLIPKART[0]));
      headerRow3.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });
      resultFLIPKART.forEach((data, index) => {
        worksheet3.addRow(Object.values(data)); // Add regular data row
      });
    }
    if (data2AMAZON.length > 0) {
      const worksheet2 = workbook.addWorksheet("AMAZON");
      // Add a row with the title "Payment Receipt Information"
      worksheet2.addRow(["Payment Receipt Information"]);

      // Get the last row where the title was added
      const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow2 = worksheet2.addRow(Object.keys(resultAMAZON[0]));
      headerRow2.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });
      // Add 2 file data rows
      resultAMAZON.forEach((data, index) => {
        worksheet2.addRow(Object.values(data)); // Add regular data row
      });
    }

    if (data2AJIO.length > 0) {
      const worksheet1 = workbook.addWorksheet("AJIO");

      // Add a row with the title "Payment Receipt Information"
      // Add a row with the title "Payment Receipt Information"
      worksheet1.addRow(["Payment Receipt Information"]);

      // Get the last row where the title was added
      const lastRow = worksheet1.getRow(worksheet1.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 1 file header row
      const headerRow1 = worksheet1.addRow(Object.keys(resultAJIO[0]));
      headerRow1.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });

      // Add 1 file data rows
      resultAJIO.forEach((data, index) => {
        worksheet1.addRow(Object.values(data)); // Add regular data row
      });
    }
    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      blob,
      `${input2MYNTRA} Payment Receipt Information ${input2AJIO}.xlsx`
    );
  };
  /////////////////////////////////////////////////////////////////////////////////////////////DOWNLOAD SECOUND FILE
  const DownloadSecoundFile = async () => {
    const workbook = new ExcelJS.Workbook();
    if (data2MYNTRA.length > 0) {
      const worksheet4 = workbook.addWorksheet("MYNTRA");

      // Add a row with the title "Payment Receipt Information"
      worksheet4.addRow(["Non Order Settlement/Adjustments"]);

      // Get the last row where the title was added
      const lastRow4 = worksheet4.getRow(worksheet4.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet4.mergeCells(worksheet4.rowCount, 1, worksheet4.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow4 = worksheet4.addRow(Object.keys(resultMYNTRA2[0]));
      headerRow4.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });

      resultMYNTRA2.forEach((data, index) => {
        worksheet4.addRow(Object.values(data)); // Add regular data row
      });
      worksheet4.addRow([" "]);
      worksheet4.addRow([" "]);
      worksheet4.addRow([" "]);
      worksheet4.addRow([
        "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
      ]);

      // Get the last row where the title was added
      const lastRow2 = worksheet4.getRow(worksheet4.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet4.mergeCells(worksheet4.rowCount, 1, worksheet4.rowCount, 20); // Merges from (row, startCol) to (row, endCol)
    }
    if (data2MYNTRASJIT.length > 0) {
      const worksheet4 = workbook.addWorksheet("MYNTRA SJIT");

      // Add a row with the title "Payment Receipt Information"
      worksheet4.addRow(["Non Order Settlement/Adjustments"]);

      // Get the last row where the title was added
      const lastRow4 = worksheet4.getRow(worksheet4.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet4.mergeCells(worksheet4.rowCount, 1, worksheet4.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow4 = worksheet4.addRow(Object.keys(resultMYNTRA2SJIT[0]));
      headerRow4.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });

      resultMYNTRA2SJIT.forEach((data, index) => {
        worksheet4.addRow(Object.values(data)); // Add regular data row
      });
      worksheet4.addRow([" "]);
      worksheet4.addRow([" "]);
      worksheet4.addRow([" "]);
      worksheet4.addRow([
        "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
      ]);

      // Get the last row where the title was added
      const lastRow2 = worksheet4.getRow(worksheet4.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet4.mergeCells(worksheet4.rowCount, 1, worksheet4.rowCount, 20); // Merges from (row, startCol) to (row, endCol)
    }

    if (data2FLIPKART.length > 0) {
      const worksheet3 = workbook.addWorksheet("FLIPKART");
      // Add a row with the title "Payment Receipt Information"
      worksheet3.addRow(["Non Order Settlement/Adjustments"]);

      // Get the last row where the title was added
      const lastRow3 = worksheet3.getRow(worksheet3.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet3.mergeCells(worksheet3.rowCount, 1, worksheet3.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow3 = worksheet3.addRow(Object.keys(resultFLIPKART2[0]));
      headerRow3.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });
      resultFLIPKART2.forEach((data, index) => {
        worksheet3.addRow(Object.values(data)); // Add regular data row
      });
      worksheet3.addRow([" "]);
      worksheet3.addRow([" "]);
      worksheet3.addRow([" "]);
      worksheet3.addRow([
        "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
      ]);

      // Get the last row where the title was added
      const lastRow2 = worksheet3.getRow(worksheet3.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet3.mergeCells(worksheet3.rowCount, 1, worksheet3.rowCount, 20); // Merges from (row, startCol) to (row, endCol)
    }
    if (data2AMAZON.length > 0) {
      const worksheet2 = workbook.addWorksheet("AMAZON");
      // Add a row with the title "Payment Receipt Information"
      worksheet2.addRow(["Non Order Settlement/Adjustments"]);

      // Get the last row where the title was added
      const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 2 file header row
      const headerRow2 = worksheet2.addRow(Object.keys(resultAMAZON2[0]));
      headerRow2.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });
      // Add 2 file data rows
      resultAMAZON2.forEach((data, index) => {
        worksheet2.addRow(Object.values(data)); // Add regular data row
      });
      worksheet2.addRow([" "]);
      worksheet2.addRow([" "]);
      worksheet2.addRow([" "]);
      worksheet2.addRow([
        "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
      ]);

      // Get the last row where the title was added
      const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)
    }

    if (data2AJIO.length > 0) {
      const worksheet1 = workbook.addWorksheet("AJIO");

      // Add a row with the title "Payment Receipt Information"
      // Add a row with the title "Payment Receipt Information"
      worksheet1.addRow(["Non Order Settlement/Adjustments"]);

      // Get the last row where the title was added
      const lastRow = worksheet1.getRow(worksheet1.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 2); // Merges from (row, startCol) to (row, endCol)
      // Add 1 file header row
      const headerRow1 = worksheet1.addRow(Object.keys(resultAJIO2[0]));
      headerRow1.eachCell((cell) => {
        cell.font = { bold: true }; // Make header bold
        cell.fill = {
          // Set fill color for header
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
      });

      // Add 1 file data rows
      resultAJIO2.forEach((data, index) => {
        worksheet1.addRow(Object.values(data)); // Add regular data row
      });
      worksheet1.addRow([" "]);
      worksheet1.addRow([" "]);
      worksheet1.addRow([" "]);
      worksheet1.addRow([
        "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
      ]);

      // Get the last row where the title was added
      const lastRow2 = worksheet1.getRow(worksheet1.rowCount);

      // Merge cells from column 1 to column 5 in the last row
      worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 20); // Merges from (row, startCol) to (row, endCol)
    }

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      blob,
      `${input2MYNTRA} Non Order Settlement/Adjustments ${input2AJIO}.xlsx`
    );
  };
  /////////////////////////////////////////////////////////////////////////////////////////////START AJIO
  const handleFormSubmitAJIO = (e) => {
    e.preventDefault();
    const Z = data2AJIO.filter((item) => {
      return item.TYPE === "KP-Outgoing Pymt - Auto";
    });
    const X = data2AJIO.filter((item) => {
      return item.TYPE === "ZK-Cust.& vend.Postings";
    });
    // console.log(Z);
    console.log(X);

    // const date = [];
    // const dub = [];
    // Z.map((item, index) => {
    //   if (date[index]) {
    //     if (item.DOCDATE === date[index]) {
    //       dub.push(item.DOCDATE);
    //     }
    //   }
    //   date.push(item.DOCDATE ? item.DOCDATE : "undefined");

    // });
    // console.log(date);
    // console.log(dub);

    // Assuming Z is your input array of objects with DOCDATE property
    const finduniqueDates = (Z) => {
      // Store all dates
      const allDates = Z.map((item) => ({
        date: item.DOCDATE,
        amount: item.AMOUNT,
      }));
      console.log(allDates);
      // Find duplicates using filter
      const duplicateDates = allDates
        .map((item) => item.date)
        .filter(
          (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
        );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      const allDates = Z.map((item) => item.DOCDATE);
      // Find duplicates using filter
      const duplicates = allDates.filter((date, index, arr) => {
        return allDates.indexOf(date) !== index;
      });
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };
    // Example usage:
    // const Z = [
    //   { DOCDATE: "2024-01-01", AMOUNT: 45454 },
    //   { DOCDATE: "2024-01-02", AMOUNT: 455 },
    //   { DOCDATE: "2024-01-01", AMOUNT: 4544 }, // duplicate
    //   { DOCDATE: "2024-01-03", AMOUNT: 4575 },
    //   { DOCDATE: "2024-01-02", AMOUNT: 4585 }, // duplicate
    //   { DOCDATE: "2024-01-10", AMOUNT: 4945 }, // duplicate
    // ];

    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);
    let q = [];
    let o = [];
    let r = [];
    let s = [];
    const V = duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1.DOCDATE === item);
      q = [...q, A];
      console.log(q);
    });
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce((acc, curr) => acc + curr.AMOUNT, 0);
      console.log(a2);
      const a = q[index][0].DOCDATE;
      const g = {
        "Payment Date": a,
        "Settled Amount": a2,
        // Remarks: "",
        // Amount: "",
      };
      o.push(g);
      setResultAJIO(o);
    });
    console.log(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1.DOCDATE === item);
        const d = {
          "Payment Date": A[0].DOCDATE,
          "Settled Amount": A[0]["AMOUNT"],
          // Remarks: "",
          // Amount: "",
        };
        o = [...o, d];
        console.log(o);
        setResultAJIO(o);
      });
    }
    console.log(resultAJIO);

    ////////////////////////////////////////////////////////////////////////////////
    let p = [];
    if (X.length > 0) {
      console.log(X);

      // Create a copy of the existing resultAJIO
      const updatedResultAJIO = X.map((item, index) => {
        // Check if there's a corresponding item in X for the current index
        if (index < X.length) {
          return {
            // Spread the existing item properties
            Remarks: X[index].TEXT, // Update Remarks
            Amount: X[index].AMOUNT * -1, // Update Amount (negated)
          };
        }
        return item; // Return the original item if no corresponding X item
      });
      console.log(updatedResultAJIO);
      // Set the new state with the updated resultAJIO
      setResultAJIO2(updatedResultAJIO);
    }
    console.log(p);
  };

  const handleFileUpload2AJIO = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result; // Changed from resultAJIO to result
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData2AJIO(parsedData);
    };
  };

  const downloadExcelAJIO = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Ajio_Sheet1");
    const worksheet2 = workbook.addWorksheet("Ajio_Sheet2");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultAJIO[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultAJIO2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultAJIO.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultAJIO2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Ajio_GST_Report_${input2AJIO}.xlsx`);
  };
  const downloadExcelAJIOErrorAJIO = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorAJIOContentAJIO);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorAJIO-file.xlsx");
  };

  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultAJIO);
  console.log(data2AJIO);
  console.log(resultAJIO2);
  /////////////////////////////////////////////////////////////////////////////////END AJIO
  /////////////////////////////////////////////////////////////////////////////////START AMAZON
  const handleFormSubmitAMAZON = (e) => {
    e.preventDefault();

    const l = data2AMAZON.filter((item) => {
      return item["type"] === "Transfer";
    });
    console.log(l);

    const Z = l.map((item) => {
      // Original date string
      const dateStr = item["date/time"];

      // Create a Date object from the date string
      const dateObj = new Date(dateStr);

      // Get the day, month (0-indexed), and year
      const day = dateObj.getUTCDate();
      const month = dateObj.getUTCMonth() + 1; // Months are 0-indexed, so add 1
      const year = dateObj.getUTCFullYear();

      // Format the date as "DD-M-YYYY"
      const formattedDate = `${day}-${month}-${year}`;

      console.log(formattedDate); // Output: "30-9-2024"
      return {
        "Payment Date": formattedDate,
        "Settled Amount": Number(item.total),
      };
    });

    console.log(Z);
    // console.log(l);
    const finduniqueDates = (Z) => {
      // Find duplicates using filter
      const duplicateDates = Z.map((item) => item.date).filter(
        (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      // Find duplicates using filter
      // console.log(Z[0]["Payment Date"]);
      const duplicates = Z.map((item) => item["Payment Date"]).filter(
        (date, index, arr) => {
          return arr.indexOf(date) !== index;
        }
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };

    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);

    let q = [];
    let o = [];
    let r = [];
    let s = [];

    // console.log(Z[0]["Settled Amount"]);
    duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1["Payment Date"] === item);
      q = [...q, A];
    });

    console.log(q);
    //  const a =  q[1].map((item) => (isNaN(item["Settled Amount"]) ? item : null));    // console.log(q[1][0]["Settled Amount"]);
    //  console.log(a);
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce(
        (acc, curr) => acc + curr["Settled Amount"],
        0
      );
      console.log(a2);
      const a = q[index][0]["Payment Date"];
      const g = {
        "Payment Date": a,
        "Settled Amount": a2 * -1,
      };
      o.push(g);
      setResultAMAZON(o);
    });
    console.log(o);
    setResultAMAZON(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1["Payment Date"] === item);
        const d = {
          "Payment Date": A[0]["Payment Date"],
          "Settled Amount": A[0]["Settled Amount"] * -1,
        };
        o = [...o, d];
        console.log(o);
        setResultAMAZON(o);
      });
    }
    console.log(resultAMAZON);
    console.log(o);

    /////////////////////////////////
    // let Adjustment = [];
    // let FBA_Inventory_Fee = [];
    // let Others = [];
    // let SAFET_Reimbursement = [];
    // let Reimbursements = [];
    // let Service_Fee = [];

    const Adjustment = [];
    const FBA_Inventory_Fee = [];
    const Others = [];
    const SAFET_Reimbursement = [];
    const Reimbursements = [];
    const Service_Fee = [];
    const Clawbacks = [];
    const Cancellation = [];
    data2AMAZON.forEach((item) => {
      switch (item.type) {
        case "Adjustment":
          Adjustment.push(item);
          break;
        case "FBA Inventory Fee":
          FBA_Inventory_Fee.push(item);
          break;
        case "Others":
          Others.push(item);
          break;
        case "SAFE-T Reimbursement":
          SAFET_Reimbursement.push(item);
          break;
        case "Reimbursements":
          Reimbursements.push(item);
          break;
        case "Service Fee":
          Service_Fee.push(item);
          break;
        case "Clawbacks":
          Clawbacks.push(item);
          break;
        case "Cancellation":
          Cancellation.push(item);
          break;
      }
    });

    // data2AMAZON.map((item, index) => {
    //   if (item.type === "Adjustment") {
    //     const adjustments = data2AMAZON.filter((dataItem) => {
    //       return dataItem.type === "Adjustment";
    //     });
    //     console.log("Adjustment", adjustments);
    //     Adjustment.push(...adjustments); // Spread operator to push individual items
    //   }
    //   if (item.type === "FBA Inventory Fee") {
    //     const x2 = data2AMAZON.filter((item) => {
    //       return item.type === "FBA Inventory Fee";
    //     });
    //     // console.log("FBA Inventory Fee", x2);
    //     FBA_Inventory_Fee.push(...x2);
    //   }
    //   if (item.type === "Others") {
    //     const x3 = data2AMAZON.filter((item) => {
    //       return item.type === "Others";
    //     });
    //     // console.log("Others", x3);
    //     Others.push(...x3);
    //   }
    //   if (item.type === "SAFE-T Reimbursement") {
    //     const x4 = data2AMAZON.filter((item) => {
    //       return item.type === "SAFE-T Reimbursement";
    //     });
    //     // console.log("SAFE-T Reimbursement", x4);
    //     SAFET_Reimbursement.push(...x4);
    //   }
    //   if (item.type === "Reimbursements") {
    //     const x5 = data2AMAZON.filter((item) => {
    //       return item.type === "Reimbursements";
    //     });
    //     // console.log("Reimbursements", x5);
    //     Reimbursements.push(...x5);
    //   }
    //   if (item.type === "Service Fee") {
    //     const x6 = data2AMAZON.filter((item) => {
    //       return item.type === "Service Fee";
    //     });
    //     // console.log("Service Fee", x6);
    //     Service_Fee.push(...x6);
    //   }
    // });

    console.log("Adjustment", Adjustment);
    console.log("FBA_Inventory_Fee", FBA_Inventory_Fee);
    console.log("Others", Others);
    console.log("SAFET_Reimbursement", SAFET_Reimbursement);
    console.log("Reimbursements", Reimbursements);
    console.log("Service_Fee", Service_Fee);
    console.log("clawbacks", Clawbacks);
    console.log("cancellation", Cancellation);

    let R = [];

    /////Adjustment////////////////
    if (Adjustment.length > 0) {
      const r = Adjustment.filter((item) => {
        return item.description !== "Other";
      });
      console.log(r); ///[]////
      const unique1 = [...new Set(r.map((item) => item.description))];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = r.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }
    ////////////////////FBA_Inventory_Fee///////////////////////////
    if (FBA_Inventory_Fee.length > 0) {
      const unique1 = [
        ...new Set(FBA_Inventory_Fee.map((item) => item.description)),
      ];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = FBA_Inventory_Fee.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        console.log(g);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }
    ////////////Others/////////////////////
    if (Others.length > 0) {
      const r = Others.filter((item) => {
        return item.description === "TDS Reimbursement";
      });
      console.log(r); ///[]////
      const unique1 = [...new Set(r.map((item) => item.description))];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = r.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }
    /////////////////SAFET_Reimbursement//////////////////////
    if (SAFET_Reimbursement.length > 0) {
      const e = SAFET_Reimbursement.reduce(
        (acc, curr) => acc + Number(curr.total),
        0
      );
      console.log(e);
      const a = { Remarks: "SPF", Amount: e };
      R = [...R, a];
    }
    ////////////////////Reimbursements/////////////////////
    if (Reimbursements.length > 0) {
      const e = Reimbursements.reduce(
        (acc, curr) => acc + Number(curr.total),
        0
      );
      console.log(e);
      const a = { Remarks: "SPF", Amount: e };
      R = [...R, a];
    }
    ////////////////////clawbacks/////////////////////
    if (Clawbacks.length > 0) {
      const e = Clawbacks.reduce((acc, curr) => acc + Number(curr.total), 0);
      console.log(e);
      const a = { Remarks: "Clawbacks", Amount: e };
      R = [...R, a];
    }
    ////////////////////cancellation/////////////////////
    if (Cancellation.length > 0) {
      const e = Cancellation.reduce((acc, curr) => acc + Number(curr.total), 0);
      console.log(e);
      const a = { Remarks: "Cancellation", Amount: e };
      R = [...R, a];
    }
    //////////Service_Fee///////////////////////////////////
    if (Service_Fee.length > 0) {
      const r = Service_Fee.filter((item) => {
        return (
          item.description === "Cost of Advertising" ||
          item.description === "Order Cancellation Charge"
        );
      });
      console.log(r); ///[]////
      const unique1 = [...new Set(r.map((item) => item.description))];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = r.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }

    /////////////////////////////////
    console.log(R);
    // Assuming R is an array of objects with Remarks and Amount properties
    const processData = (data) => {
      // Filter items with "SPF" remarks
      const filteredItems = data.filter((x) => x.Remarks === "SPF");
      console.log("Filtered SPF items:", filteredItems);

      // Sum up the amounts of filtered items
      const totalAmount = filteredItems.reduce(
        (acc, curr) => acc + curr.Amount,
        0
      );
      console.log("Total SPF amount:", totalAmount);

      // Create new object with the total
      const summaryObject = { Remarks: "SPF", Amount: totalAmount };
      console.log("Summary object:", summaryObject);
      const newdata = data.filter((item) => {
        return item.Remarks !== "SPF" && item.Amount !== 0;
      });
      // Add the summary object to the original array
      // Using spread operator to create a new array
      const newArray = [...newdata, summaryObject];
      console.log("Final array:", newArray);

      return newArray;
    };
    const resultAMAZON_ = processData(R);
    console.log(resultAMAZON_);
    setResultAMAZON2(resultAMAZON_);
  };
  const handleXlsvCsvAMAZON = (event, setData) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension === "csv") {
      console.log("CSV");
      // handleFileUpload2AMAZON(file, setData2AMAZON);
      // handleCSVFileAMAZON(event,setData2AMAZON);
      handleCSVtoXLSX(event, setData2AMAZON);
      // handleCsvFile(file, setData);
    } else if (["xlsx", "xls"].includes(fileExtension)) {
      console.log("XLSV");
      handleExcelFileAMAZON(event, setData2AMAZON);
    } else {
      console.errorAMAZON(
        "Unsupported file format. Please upload a CSV or Excel file."
      );
    }
  };

  // const handleExcelFileAMAZON = (e, setData2AMAZON) => {
  //   // if (zx == true) {

  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     console.log(parsedData)
  //     const dataWithout10 = parsedData.slice(10);
  //     console.log(dataWithout10);
  //     setData2AMAZON(parsedData);
  //     // };
  //   };
  // };

  const handleExcelFileAMAZON = (e, setData2AMAZON) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Get the range of the sheet
      const range = XLSX.utils.decode_range(sheet["!ref"]);

      // First, get the header row (11th row)
      const headerRow = {};
      const row = 11; // 11th row (0-based index)
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        if (sheet[cellAddress]) {
          headerRow[XLSX.utils.encode_col(col)] = sheet[cellAddress].v;
        }
      }

      // Modify the range to start from row 12
      range.s.r = 12; // Start from 12th row
      sheet["!ref"] = XLSX.utils.encode_range(range);

      // Now parse the data using the custom header row
      const parsedData = XLSX.utils.sheet_to_json(sheet, {
        header: Object.values(headerRow),
      });

      setData2AMAZON(parsedData);
      console.log("Headers from row 11:", headerRow);
      console.log("Parsed data:", parsedData);
    };
  };
  // const handleFileUpload2AMAZON = (file, setData2AMAZON) => {
  //   if (file) {
  //     convertCsvToJsonAMAZON(file, (data) => {
  //       setData2AMAZON(data);
  //       console.log("Converted JSON Data:", data);
  //     });
  //   }
  // };

  // const convertCsvToJsonAMAZON = (csvFile, callback) => {
  //   Papa.parse(csvFile, {
  //     header: true, // This tells PapaParse to use the first row as headers
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       // Changed from resultAMAZON to result
  //       callback(result.data);
  //     },
  //     error: (error) => {
  //       // Changed from errorAMAZON to error
  //       console.error("Error parsing CSV: ", error); // Changed from console.errorAMAZON to console.error
  //     },
  //   });
  // };

  // const handleCSVFileAMAZON = (e, setData2AMAZON) => {
  //   const reader = new FileReader();
  //   reader.readAsText(e.target.files[0]);

  //   reader.onload = (e) => {
  //     const csvData = e.target.result;

  //     Papa.parse(csvData, {
  //       complete: (results) => {
  //         // Get headers from 11th row
  //         const headers = results.data[11];

  //         // Create objects using the headers starting from 12th row
  //         const parsedData = results.data.slice(12).map(row => {
  //           const obj = {};
  //           row.forEach((cell, index) => {
  //             if (headers[index]) {
  //               obj[headers[index]] = cell;
  //             }
  //           });
  //           return obj;
  //         });

  //         // setData2AMAZON(parsedData);
  //         console.log('Headers from row 11:', headers);
  //         console.log('Parsed data:', parsedData);
  //         const newparsedData = [];
  //         const new1 =parsedData.map((item,index) => {
  //           let str = item.total;
  //           // console.log(str,index);
  //           let num = parseFloat(str.replace(/,/g, ''));
  //           return {...item, total: num}
  //         })
  //         console.log(new1);
  //         let str = "-1003.62";
  //           let num = parseFloat(str.replace(/,/g, ''));
  //         console.log(num);
  //       }
  //     });
  //   };
  // };

  const handleCSVtoXLSX = (e, setData2AMAZON) => {
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);

    reader.onload = (e) => {
      const csvData = e.target.result;

      Papa.parse(csvData, {
        complete: (results) => {
          // Get headers from 11th row
          const headers = results.data[11];

          // Create objects using the headers starting from 12th row
          const parsedData = results.data
            .slice(12)
            .map((row) => {
              const obj = {};
              row.forEach((cell, index) => {
                if (headers[index] && row.length === headers.length) {
                  obj[headers[index]] = cell;
                }
              });

              if (row.length === headers.length) {
                return obj;
              }
            })
            .filter((obj) => obj !== undefined); // This will remove any undefined values
          console.log(parsedData);
          // Optional: Modify the parsed data as needed
          const newParsedData = parsedData.map((item) => {
            let str = item.total;
            if (str) {
              let num = parseFloat(str.replace(/,/g, ""));
              return { ...item, total: num };
            }
          });

          console.log("Headers from row 11:", headers);
          console.log("Parsed data:", newParsedData);

          // Optionally set the state with the parsed data
          setData2AMAZON(newParsedData);
        },
      });
    };
  };
  const downloadExcelAMAZON = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Payment Receipt Information");
    const worksheet2 = workbook.addWorksheet("Non Order Settlement");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultAMAZON[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultAMAZON2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultAMAZON.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultAMAZON2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Amazon_Bank_Settlement.xlsx`);
  };
  const downloadExcelAMAZONErrorAMAZON = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorAMAZONContentAMAZON);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorAMAZON-file.xlsx");
  };
  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultAMAZON);
  console.log(data2AMAZON);
  //////////////////////////////////////////////////////////////////////////////////END AMAZON
  //////////////////////////////////////////////////////////////////////////////////START FLIPKART
  const handleFormSubmitFLIPKART = async (e) => {
    e.preventDefault();

    const a = [];
    const Ans1 = [];
    const GST = [];
    for (let i = 2; i < data2FLIPKART.length; i++) {
      const product = data2FLIPKART[i]
        .map((item, index) => {
          const paymentDateKey = Object.keys(item).find(
            (key) =>
              key.toLowerCase().includes("payment") &&
              key.toLowerCase().includes("date")
          );
          const BanksettleKey = Object.keys(item).find(
            (key) =>
              key.toLowerCase().includes("settlement") &&
              key.toLowerCase().includes("value")
          );
          if (i < 8) {
            if (sheetnamesFLIPKART[i] !== "Storage_Recall") {
              // console.log(item);

              return {
                "Payment Date": item[paymentDateKey],
                "Settled Amount": item[BanksettleKey],
              };
            }

            if (sheetnamesFLIPKART[i] === "Storage_Recall") {
              console.log(item);
              const gstTotal = data2FLIPKART[i].reduce((acc, curr) => {
                return acc + curr["Removal Fee Units"];
              }, 0);

              console.log(gstTotal);
              GST.push(gstTotal);

              return {
                "Payment Date": item[paymentDateKey],
                "Settled Amount":
                  item[BanksettleKey] + item["Removal Fee Units"],
              };
            }
          }

          if (i > 8 && i < 10) {
            return {
              "Payment Date": item[paymentDateKey],
              "Settled Amount": item[BanksettleKey],
            };
          }

          return null;
        })
        .filter(Boolean); // Remove null values if needed
      a.push(product);
    }
    console.log(a);
    const b = a.filter((item) => {
      return item.length > 0;
    });
    console.log(b);
    const c = b.flat();
    console.log(c);
    // const v = c.map((item) => {
    //   return item["Payment Date"];
    // });
    // console.log(v);
    const unique = [...new Set(c.map((item) => item["Payment Date"]))];
    console.log(unique);

    const i = unique.map((item) => {
      const d = c.filter((item2) => item2["Payment Date"] === item);
      const fv = d.reduce((acc, curr) => {
        return acc + Number(curr["Settled Amount"]);
      }, 0);
      const date = d[0]["Payment Date"].split("-");
      const year = date[0];
      const month = date[1];
      const day = date[2];
      return {
        "Payment Date": `${day}-${month}-${year}`,
        "Settled Amount": fv,
      };
    });
    console.log(i);
    setResultFLIPKART(i);
    //////////////////////////////////////////////////////
    const array01 = [];
    const array02 = [];
    for (let i = 3; i < data2FLIPKART.length; i++) {
      const a001 = data2FLIPKART[i]
        .map((item) => {
          const paymentDateKey = Object.keys(item).find(
            (key) =>
              key.toLowerCase().includes("payment") &&
              key.toLowerCase().includes("date")
          );
          const BanksettleKey = Object.keys(item).find(
            (key) =>
              key.toLowerCase().includes("settlement") &&
              key.toLowerCase().includes("value")
          );

          if (i < 8) {
            if (sheetnamesFLIPKART[i] !== "Storage_Recall") {
              return {
                Name: sheetnamesFLIPKART[i],
                "Settled Amount": item[BanksettleKey],
              };
            }
            if (sheetnamesFLIPKART[i] === "Storage_Recall")
              return {
                Name: sheetnamesFLIPKART[i],
                "Settled Amount": item[BanksettleKey],
                GST: item["Removal Fee Units"],
              };
          }
          if (i > 8 && i < 10) {
            return {
              Name: sheetnamesFLIPKART[i],
              "Settled Amount": item[BanksettleKey],
            };
          }
          return null;
        })
        .filter(Boolean);
      array01.push(a001);
    }
    const step2 = array01.filter((item) => {
      return item.length > 0;
    });

    const step3 = step2.map((item, index) => {
      const b001 = item.reduce((acc, curr) => {
        return acc + curr["Settled Amount"];
      }, 0);

      console.log(b001);

      if (item[0].Name === "Storage_Recall") {
        console.log(item);
        const b002 = item.reduce((acc, curr) => {
          return acc + curr["GST"];
        }, 0);

        console.log(`GST${index}`, b002);

        const c001 = b002 + b001;
        console.log(c001);

        return {
          Name: item[0]["Name"],
          "Settled Amount": c001,
        };
      }

      console.log(`settled amount${index}`, b001);

      return {
        Name: item[0]["Name"],
        "Settled Amount": b001,
      };
    });
    console.log(step3);
    console.log(array01);
    console.log(step2);
    console.log(sheetnamesFLIPKART);
    setResultFLIPKART2(step3);
  };

  // const handleFileUpload2FLIPKART = (e) => {
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);

  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     console.log(workbook);

  //     for (let i = 0; i < workbook.SheetNames.length; i++) {
  //       // Changed from SheetNamesFLIPKART to SheetNames
  //       const sheetName = workbook.SheetNames[i]; // Changed from SheetNamesFLIPKART to SheetNames
  //       setSheetnamesFLIPKART((prev) => [...prev, sheetName]); // Changed 'pre' to 'prev' for clarity

  //       const sheet = workbook.Sheets[sheetName];
  //       const parsedData = XLSX.utils.sheet_to_json(sheet);
  //       setData2FLIPKART((prev) => [...prev, parsedData]); // Changed 'e' to 'prev' to avoid confusion with event
  //     }
  //   };
  // };

  const handleFileUpload2FLIPKART = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      for (let i = 0; i < workbook.SheetNames.length; i++) {
        const sheetName = workbook.SheetNames[i];
        setSheetnamesFLIPKART((prev) => [...prev, sheetName]); // Changed 'pre' to 'prev' for clarity

        const sheet = workbook.Sheets[sheetName];

        // Get the range of the sheet
        const range = XLSX.utils.decode_range(sheet["!ref"]);

        // First, get the header row (11th row)
        const headerRow = {};
        const row = 1; // 11th row (0-based index)
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (sheet[cellAddress]) {
            headerRow[XLSX.utils.encode_col(col)] = sheet[cellAddress].v;
          }
        }

        // Modify the range to start from row 12
        range.s.r = 2; // Start from 12th row
        sheet["!ref"] = XLSX.utils.encode_range(range);

        // Now parse the data using the custom header row
        const parsedData = XLSX.utils.sheet_to_json(sheet, {
          header: Object.values(headerRow),
        });

        setData2FLIPKART((pre) => [...pre, parsedData]);
        console.log("Headers from row 11:", headerRow);
        console.log("Parsed data:", parsedData);
      }
    };
  };

  const downloadExcelFLIPKART = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Payment Receipt Information");
    const worksheet2 = workbook.addWorksheet("Non Order Settlement");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultFLIPKART[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultFLIPKART2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultFLIPKART.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultFLIPKART2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Flipkart_Bank_Settlement.xlsx`);
  };
  const downloadExcelFLIPKARTErrorFLIPKART = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorFLIPKARTContentFLIPKART);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorFLIPKART-file.xlsx");
  };
  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultFLIPKART);
  console.log(data2FLIPKART);
  //////////////////////////////////////////////////////////////////////////////////////END FLIPKART
  //////////////////////////////////////////////////////////////////////////////////////START MYNTRA
  const handleFormSubmitMYNTRA = (e) => {
    e.preventDefault();
    const Z = data2MYNTRA.map((item) => {
      return {
        "Payment Date": item.Payment_Date,
        "Settled Amount": item.Settled_Amount,
      };
    });

    const X = data2MYNTRA.filter((item) => {
      return item.Order_Type === "NOD";
    });
    console.log(X);
    console.log(Z);
    const finduniqueDates = (Z) => {
      // Find duplicates using filter
      const duplicateDates = Z.map((item) => item.date).filter(
        (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      // Find duplicates using filter
      console.log(Z[0]["Payment Date"]);
      const duplicates = Z.map((item) => item["Payment Date"]).filter(
        (date, index, arr) => {
          return arr.indexOf(date) !== index;
        }
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };
    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);

    let q = [];
    let o = [];
    let r = [];
    let s = [];
    duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1["Payment Date"] === item);
      q = [...q, A];
    });
    console.log(q);
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce(
        (acc, curr) => acc + curr["Settled Amount"],
        0
      );
      console.log(a2);
      const a = q[index][0]["Payment Date"];
      const g = {
        "Payment Date": a,
        "Settled Amount": a2,
      };
      o.push(g);
      setResultMYNTRA(o);
    });
    console.log(o);
    setResultMYNTRA(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1["Payment Date"] === item);
        const d = {
          "Payment Date": A[0]["Payment Date"],
          "Settled Amount": A[0]["Settled Amount"],
          // Remarks: "",
          // Amount: "",
        };
        o = [...o, d];
        console.log(o);
        setResultMYNTRA(o);
      });
    }
    console.log(resultMYNTRA);

    /////////////////////////////////
    let p = [];
    if (X.length > 0) {
      console.log(X);

      // Create a copy of the existing resultMYNTRA
      const updatedResultMYNTRA = X.map((item, index) => {
        // Check if there's a corresponding item in X for the current index
        return {
          // Spread the existing item properties
          Remarks: item.NOD_Comment, // Update Remarks
          Amount: item.Settled_Amount, // Update Amount (negated)
        };
      });
      console.log(updatedResultMYNTRA);
      // Set the new state with the updated resultMYNTRA
      setResultMYNTRA2(updatedResultMYNTRA);
    }
    console.log(p);
  };
  const handleFileUpload2MYNTRA = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      // Convert Excel date serial numbers to formatted date strings
      const formattedData = parsedData.map((row) => {
        // Specifically convert Payment_Date if it exists
        if (row.Payment_Date && typeof row.Payment_Date === "number") {
          row.Payment_Date = excelSerialToFormattedDateMYNTRA(row.Payment_Date);
        }
        return row;
      });

      setData2MYNTRA(formattedData);
    };
  };

  // Function to convert Excel serial date to formatted date string
  function excelSerialToFormattedDateMYNTRA(serial) {
    // Handle potential Excel date serialization quirks
    if (serial < 1) return "";

    const excelEpoch = new Date(1900, 0, 1); // January is 0 in JavaScript Date

    // Adjust for Excel's leap year bug
    const adjustedSerial = serial > 59 ? serial - 1 : serial;

    const date = new Date(
      excelEpoch.getTime() + (adjustedSerial - 1) * 24 * 60 * 60 * 1000
    );

    return `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
  }
  const downloadExcelMYNTRA = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Payment Receipt Information");
    const worksheet2 = workbook.addWorksheet("Non Order Settlement");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultMYNTRA[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultMYNTRA2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultMYNTRA.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultMYNTRA2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Myntra_Bank_Settlement_Report_${input2MYNTRA}.xlsx`);
  };
  const downloadExcelMYNTRAErrorMYNTRA = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorMYNTRAContentMYNTRA);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorMYNTRA-file.xlsx");
  };

  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultMYNTRA);
  console.log(data2MYNTRA);
  console.log(resultMYNTRA2);
  /////////////////////////////////////////////////////////////////////////////////////////////////END MYNTRA 
  //////////////////////////////////////////////////////////////////////////////////////START MYNTRA SJIT
  const handleFormSubmitMYNTRASJIT = (e) => {
    e.preventDefault();
    const Z = data2MYNTRASJIT.map((item) => {
      return {
        "Payment Date": item.Payment_Date,
        "Settled Amount": item.Settled_Amount,
      };
    });

    const X = data2MYNTRASJIT.filter((item) => {
      return item.Order_Type === "NOD";
    });
    console.log(X);
    console.log(Z);
    const finduniqueDates = (Z) => {
      // Find duplicates using filter
      const duplicateDates = Z.map((item) => item.date).filter(
        (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      // Find duplicates using filter
      console.log(Z[0]["Payment Date"]);
      const duplicates = Z.map((item) => item["Payment Date"]).filter(
        (date, index, arr) => {
          return arr.indexOf(date) !== index;
        }
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };
    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);

    let q = [];
    let o = [];
    let r = [];
    let s = [];
    duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1["Payment Date"] === item);
      q = [...q, A];
    });
    console.log(q);
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce(
        (acc, curr) => acc + curr["Settled Amount"],
        0
      );
      console.log(a2);
      const a = q[index][0]["Payment Date"];
      const g = {
        "Payment Date": a,
        "Settled Amount": a2,
      };
      o.push(g);
      setResultMYNTRASJIT(o);
    });
    console.log(o);
    setResultMYNTRASJIT(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1["Payment Date"] === item);
        const d = {
          "Payment Date": A[0]["Payment Date"],
          "Settled Amount": A[0]["Settled Amount"],
          // Remarks: "",
          // Amount: "",
        };
        o = [...o, d];
        console.log(o);
        setResultMYNTRASJIT(o);
      });
    }
    console.log(resultMYNTRASJIT);

    /////////////////////////////////
    let p = [];
    if (X.length > 0) {
      console.log(X);

      // Create a copy of the existing resultMYNTRA
      const updatedResultMYNTRASJIT = X.map((item, index) => {
        // Check if there's a corresponding item in X for the current index
        return {
          // Spread the existing item properties
          Remarks: item.NOD_Comment, // Update Remarks
          Amount: item.Settled_Amount, // Update Amount (negated)
        };
      });
      console.log(updatedResultMYNTRASJIT);
      // Set the new state with the updated resultMYNTRA
      setResultMYNTRA2SJIT(updatedResultMYNTRASJIT);
    }
    console.log(p);
  };
  const handleFileUpload2MYNTRASJIT = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      // Convert Excel date serial numbers to formatted date strings
      const formattedData = parsedData.map((row) => {
        // Specifically convert Payment_Date if it exists
        if (row.Payment_Date && typeof row.Payment_Date === "number") {
          row.Payment_Date = excelSerialToFormattedDateMYNTRA(row.Payment_Date);
        }
        return row;
      });

      setData2MYNTRASJIT(formattedData);
    };
  };

  // Function to convert Excel serial date to formatted date string
  function excelSerialToFormattedDateMYNTRA(serial) {
    // Handle potential Excel date serialization quirks
    if (serial < 1) return "";

    const excelEpoch = new Date(1900, 0, 1); // January is 0 in JavaScript Date

    // Adjust for Excel's leap year bug
    const adjustedSerial = serial > 59 ? serial - 1 : serial;

    const date = new Date(
      excelEpoch.getTime() + (adjustedSerial - 1) * 24 * 60 * 60 * 1000
    );

    return `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
  }
  const downloadExcelMYNTRASJIT = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Payment Receipt Information");
    const worksheet2 = workbook.addWorksheet("Non Order Settlement");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultMYNTRASJIT[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultMYNTRA2SJIT[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultMYNTRASJIT.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultMYNTRA2SJIT.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Myntra_Bank_Settlement_Report_${input2MYNTRASJIT}.xlsx`);
  };
  const downloadExcelMYNTRAErrorMYNTRASJIT = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorMYNTRAContentMYNTRASJIT);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorMYNTRA-file.xlsx");
  };

  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultMYNTRASJIT);
  console.log(data2MYNTRASJIT);
  console.log(resultMYNTRA2SJIT);
  /////////////////////////////////////////////////////////////////////////////////////////////////END MYNTRA SJIT

  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Bank Settlement</b>
        </h3>
      </div>

      <section class="u-section-container">
        <h3 className="titleforportal">AJIO</h3>

        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload2AJIO}
            multiple
          />
        </div>
        {/* <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2AJIO(e.target.value)}
          />
        </div> */}
      </section>
      <section class="u-section-container">
        <h3 className="titleforportal">AMAZON</h3>
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls,.csv"
            onChange={handleXlsvCsvAMAZON}
            multiple
          />
        </div>
        {/* <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2AMAZON(e.target.value)}
          />
        </div> */}
      </section>
      <section class="u-section-container">
        <h3 className="titleforportal">FLIPKART</h3>
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload2FLIPKART}
            multiple
          />
        </div>
        {/* <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2FLIPKART(e.target.value)}
          />
        </div> */}
      </section>
      <section class="u-section-container">
        <h3 className="titleforportal">MYNTRA</h3>
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload2MYNTRA}
            multiple
          />
        </div>
        {/* <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2MYNTRA(e.target.value)}
          />
        </div> */}
      </section>
      <section class="u-section-container">
        <h3 className="titleforportal">MYNTRA SJIT</h3>
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload2MYNTRASJIT}
            multiple
          />
        </div>
        {/* <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2MYNTRA(e.target.value)}
          />
        </div> */}
      </section>
      <section class="u-section-container">
        {/* <h3 className="titleforportal">MYNTRA</h3>
        <p className="paragraphTitle">SOA file :</p> */}
        <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER BRAND NAME"
            onChange={(e) => setInput2MYNTRA(e.target.value)}
          />
        </div>

        <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2AJIO(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              ></div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={RunAll}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={DownloadFiles}
                  >
                    Download Result
                  </div>
                  {errorAJIOContentAJIO.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelAJIOErrorAJIO}
                    >
                      Download ErrorAJIO Data
                    </div>
                  ) : null}
                  {/* {errorAJIO ? (
                    <div>
                      <h3 className="errorAJIOfor<200">
                        Tax Exclusive Gross value lesser than 100
                      </h3>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WholeBankSettlement;
