import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {
 
  const [data2AJIO, setData2AJIO] = useState([]);
  const [errorAJIOContentAJIO, setErrorAJIOContentAJIO] = useState([]);
  const [input2AJIO, setInput2AJIO] = useState();
  const [errorAJIO, setErrorAJIO] = useState(false);
  const [salesAJIO, setSalesAJIO] = useState([]);
  const [returnsAJIO, setReturnsAJIO] = useState([]);
  const [resultAJIO, setResultAJIO] = useState([]);
  const [resultAJIO2, setResultAJIO2] = useState([]);

  console.log(salesAJIO);
  console.log(returnsAJIO);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmitAJIO = (e) => {
    e.preventDefault();
    const Z = data2AJIO.filter((item) => {
      return item.TYPE === "KP-Outgoing Pymt - Auto";
    });
    const X = data2AJIO.filter((item) => {
      return item.TYPE === "ZK-Cust.& vend.Postings";
    });
    // console.log(Z);
    console.log(X);

    // const date = [];
    // const dub = [];
    // Z.map((item, index) => {
    //   if (date[index]) {
    //     if (item.DOCDATE === date[index]) {
    //       dub.push(item.DOCDATE);
    //     }
    //   }
    //   date.push(item.DOCDATE ? item.DOCDATE : "undefined");

    // });
    // console.log(date);
    // console.log(dub);

    // Assuming Z is your input array of objects with DOCDATE property
    const finduniqueDates = (Z) => {
      // Store all dates
      const allDates = Z.map((item) => ({
        date: item.DOCDATE,
        amount: item.AMOUNT,
      }));
      console.log(allDates);
      // Find duplicates using filter
      const duplicateDates = allDates
        .map((item) => item.date)
        .filter(
          (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
        );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      const allDates = Z.map((item) => item.DOCDATE);
      // Find duplicates using filter
      const duplicates = allDates.filter((date, index, arr) => {
        return allDates.indexOf(date) !== index;
      });
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };
    // Example usage:
    // const Z = [
    //   { DOCDATE: "2024-01-01", AMOUNT: 45454 },
    //   { DOCDATE: "2024-01-02", AMOUNT: 455 },
    //   { DOCDATE: "2024-01-01", AMOUNT: 4544 }, // duplicate
    //   { DOCDATE: "2024-01-03", AMOUNT: 4575 },
    //   { DOCDATE: "2024-01-02", AMOUNT: 4585 }, // duplicate
    //   { DOCDATE: "2024-01-10", AMOUNT: 4945 }, // duplicate
    // ];

    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);
    let q = [];
    let o = [];
    let r = [];
    let s = [];
    const V = duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1.DOCDATE === item);
      q = [...q, A];
      console.log(q);
    });
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce((acc, curr) => acc + curr.AMOUNT, 0);
      console.log(a2);
      const a = q[index][0].DOCDATE;
      const g = {
        "Payment Date": a,
        "Settled Amount": a2,
        // Remarks: "",
        // Amount: "",
      };
      o.push(g);
      setResultAJIO(o);
    });
    console.log(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1.DOCDATE === item);
        const d = {
          "Payment Date": A[0].DOCDATE,
          "Settled Amount": A[0]["AMOUNT"],
          // Remarks: "",
          // Amount: "",
        };
        o = [...o, d];
        console.log(o);
        setResultAJIO(o);
      });
    }
    console.log(resultAJIO);

    ////////////////////////////////////////////////////////////////////////////////
    let p = [];
    if (X.length > 0) {
      console.log(X);

      // Create a copy of the existing resultAJIO
      const updatedResultAJIO = X.map((item, index) => {
        // Check if there's a corresponding item in X for the current index
        if (index < X.length) {
          return {
            // Spread the existing item properties
            Remarks: X[index].TEXT, // Update Remarks
            Amount: X[index].AMOUNT * -1, // Update Amount (negated)
          };
        }
        return item; // Return the original item if no corresponding X item
      });
      console.log(updatedResultAJIO);
      // Set the new state with the updated resultAJIO
      setResultAJIO2(updatedResultAJIO);
    }
    console.log(p);

    ////////////////////////////////////////////////////////////////////////////////
    // // Return object with total amount and first payment date
    // return {
    //   Amount: a2,
    //   "Payment Date": B[0] ? B[0]["Payment Date"] : null,
    // };

    // let F1 = [];
    // V.map((item) => {
    //   console.log(item);
    //   const newObject = { Amount: item };
    //   F1.push(newObject);
    // });
    // console.log(F1);

    // const updatedData2AJIO = Array.isArray(flatArray1)
    //   ? flatArray1.map((item) => {
    //       const keysToRemove = [
    //         "Seller Gstin",
    //         // "Transaction Type",
    //         "Shipment Id",
    //         "Shipment Date",
    //         "Order Date",
    //         "Shipment Item Id",
    //         "Item Description",
    //         "Asin",
    //         "Product Tax Code",
    //         "Bill From City",
    //         "Bill From State",
    //         "Bill From Country",
    //         "Bill From Postal Code",
    //         "Ship From City",
    //         "Ship From State",
    //         "Ship From Country",
    //         "Ship From Postal Code",
    //         "Ship To City",
    //         "Ship To Country",
    //         "Ship To Postal Code",
    //         "Cgst Rate",
    //         "Sgst Rate",
    //         "Utgst Rate",
    //         "Igst Rate",
    //         "Compensatory Cess Rate",
    //         "Principal Amount",
    //         "Principal Amount Basis",
    //         "Compensatory Cess Tax",
    //         "Shipping Amount",
    //         "Shipping Amount Basis",
    //         "Shipping Cgst Tax",
    //         "Shipping Sgst Tax",
    //         "Shipping Utgst Tax",
    //         "Shipping Igst Tax",
    //         "Shipping Cess Tax",
    //         "Gift Wrap Amount",
    //         "Gift Wrap Amount Basis",
    //         "Gift Wrap Cgst Tax",
    //         "Gift Wrap Sgst Tax",
    //         "Gift Wrap Utgst Tax",
    //         "Gift Wrap Igst Tax",
    //         "Gift Wrap Compensatory Cess Tax",
    //         "Item Promo Discount",
    //         "Item Promo Discount Basis",
    //         "Item Promo Tax",
    //         "Shipping Promo Discount",
    //         "Shipping Promo Discount Basis",
    //         "Shipping Promo Tax",
    //         "Gift Wrap Promo Discount",
    //         "Gift Wrap Promo Discount Basis",
    //         "Gift Wrap Promo Tax",
    //         "Tcs Cgst Rate",
    //         "Tcs Cgst Amount",
    //         "Tcs Sgst Rate",
    //         "Tcs Sgst Amount",
    //         "Tcs Utgst Rate",
    //         "Tcs Utgst Amount",
    //         "Tcs Igst Rate",
    //         "Tcs Igst Amount",
    //         "Warehouse Id",
    //         "Fulfillment Channel",
    //         "Payment Method Code",
    //         "Bill To City",
    //         "Bill To State",
    //         "Bill To Country",
    //         "Bill To Postalcode",
    //         // "Customer Bill To Gstid",
    //         "Customer Ship To Gstid",
    //         "Buyer Name",
    //         "Credit Note No",
    //         "Credit Note Date",
    //         "Irn Number",
    //         "Irn Filing Status",
    //         "Irn Date",
    //         "Irn ErrorAJIO Code",
    //         "Shipping Cess Tax Amount",
    //       ];

    //       const jsonData1 = { ...item }; // Create a shallow copy of the item
    //       keysToRemove.forEach((key) => {
    //         delete jsonData1[key]; // Remove the keys from the copied object
    //       });
    //       return jsonData1; // Return the modified object
    //     })
    //   : (() => {
    //       console.errorAJIO("data2AJIO is not an array or is undefined.");
    //       return []; // Return an empty array or handle the errorAJIO as needed
    //     })();

    // console.log(updatedData2AJIO);
    // const B = updatedData2AJIO.filter((item) => item["Invoice Number"] !== "");

    // const C = B.map((item) => {
    //   const dateString = item["Invoice Date"];
    //   const month0 = dateString.split(" ")[0];

    //   // Split the date string into components
    //   const [year, month, day] = month0.split("-");

    //   return { ...item, "Invoice Date": `${day}-${month}-${year}` };
    // });

    // console.log(C);

    // const R = C.filter((item) => {
    //   return item["Transaction Type"] !== "Refund";
    // });

    // console.log(R);
    // const T = R.map((item) => {
    //   const { "Transaction Type": _, ...rest } = item;
    //   return rest;
    // });
    // setSalesAJIO(T);

    // const Y = C.filter((item) => {
    //   return (
    //     item["Transaction Type"] !== "FreeReplacement" &&
    //     item["Transaction Type"] !== "Shipment"
    //   );
    // });

    // console.log(Y);
    // const U = Y.map((item) => {
    //   const { "Transaction Type": _, ...rest } = item;
    //   return rest;
    // });
    // setReturnsAJIO(U);

    // const i = T.map((item, index) => {
    //   if (item["Tax Exclusive Gross"] < 100) {
    //     setHighlight2((pre) => [...pre, index]);
    //     setErrorAJIO(true);
    //   }
    // });

    // const C = B.map((item)=>
    //     item.SHIPPED_QUANTITY === 0 ? 1 : item.SHIPPED_QUANTITY
    // )
    // const C = B.map(item => item.SHIPPED_QUANTITY === "0" ? "1" : item.SHIPPED_QUANTITY);
    //   const C = B.map(item => ({
    //     ...item,
    //     SHIPPED_QUANTITY: item.SHIPPED_QUANTITY === "0" && item.SHIPPED_QUANTITY === 0 ? 1 : item.SHIPPED_QUANTITY
    // }));
    // const dateString = "Thu Aug 01 09:59:22 IST 2024";
    // const month = dateString.split(" ")[1]; // Split by spaces and get the second element
    // console.log(month); // Output: "Aug"
    // const E = C.map((item) => {
    //   // // const dateString = item.SELLER_INVOICE_DATE;
    //   // const dateString = "Oct 01 10:24:14 IST 2024";

    //   // // Create a Date object from the date string
    //   // const date = new Date(dateString);

    //   // // Format the date to "DD-MM-YYYY"
    //   // const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //   // const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //   // const year = date.getFullYear(); // Get full year

    //   // const formattedDate = `${day}-${month}-${year}`;
    //   // console.log(formattedDate, dateString, date); // Output: "01-10-2024"
    //   // return { ...item, SELLER_INVOICE_DATE: formattedDate };

    //   const dateString = item.SELLER_INVOICE_DATE;

    //   // Split the date string into components
    //   const parts = dateString.split(" ");
    //   const month = parts[1]; // "Oct"
    //   const day = parts[2]; // "01"
    //   const time = parts[3]; // "10:24:14"
    //   const year = parts[5]; // "2024"

    //   // Create a new date string in a more reliable format
    //   const formattedDateString = `${month} ${day} ${year} ${time}`;

    //   // Create a Date object
    //   const date = new Date(formattedDateString);

    //   // Check if the date is valid
    //   if (isNaN(date.getTime())) {
    //     console.errorAJIO(`Invalid date: ${formattedDateString}`);
    //   } else {
    //     // Format the date to "DD-MM-YYYY"
    //     const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //     const year = date.getFullYear(); // Get full year

    //     const formattedDate = `${day}-${month}-${year}`;
    //     console.log(formattedDate); // Output: "01-10-2024"
    //     return { ...item, SELLER_INVOICE_DATE: formattedDate };
    //   }
    // });

    // const dateString = "Tue Oct 01 10:24:14 IST 2024";

    // // Split the date string into components
    // const parts = dateString.split(" ");
    // const month = parts[1]; // "Oct"
    // const day = parts[2]; // "01"
    // const time = parts[3]; // "10:24:14"
    // const year = parts[5]; // "2024"

    // // Create a new date string in a more reliable format
    // const formattedDateString = `${month} ${day} ${year} ${time}`;

    // // Create a Date object
    // const date = new Date(formattedDateString);

    // // Check if the date is valid
    // if (isNaN(date.getTime())) {
    //     console.errorAJIO(`Invalid date: ${formattedDateString}`);
    // } else {
    //     // Format the date to "DD-MM-YYYY"
    //     const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //     const year = date.getFullYear(); // Get full year

    //     const formattedDate = `${day}-${month}-${year}`;
    //     console.log(formattedDate); // Output: "01-10-2024"
    // }

    // const D = E.map((item) => {
    //   const taxableValue =
    //     Number(item.TOTAL_VALUE) -
    //     (Number(item.CGST_AMOUNT) +
    //       Number(item.IGST_AMOUNT) +
    //       Number(item.SGST_AMOUNT));

    //   // Set errorAJIO based on the taxable value
    //   console.log(taxableValue);
    //   if (taxableValue < 100) {
    //     setErrorAJIO(true);
    //   }

    //   return {
    //     PURCHASE_ORDER_NUMBER: item.PURCHASE_ORDER_NUMBER,
    //     Party_Name: "RELIANCE RETAIL LIMITED",
    //     GSTIN_Number: input2,
    //     SELLER_INVOICE_NO: item.SELLER_INVOICE_NO,
    //     SELLER_INVOICE_DATE: item.SELLER_INVOICE_DATE,
    //     BASE_PRICE: item.BASE_PRICE,
    //     TAXABLE_VALUE: taxableValue,
    //     ...item,
    //   };
    // });

    // setData2AJIO(D);
  };

  const handleFileUpload2AJIO = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2AJIO(parsedData);
      // };
    };
  };

  // const downloadExcelAJIO = () => {
  //   const worksheet1 = XLSX.utils.json_to_sheet(resultAJIO);
  //   const worksheet2 = XLSX.utils.json_to_sheet(resultAJIO2);

  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet1, "Ajio_Sheet1");
  //   XLSX.utils.book_append_sheet(workbook, worksheet2, "Ajio_Sheet2");

  //   XLSX.writeFile(workbook, "Ajio_Bank_Settlement_Report.xlsx");
  // };

  const downloadExcelAJIO = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Ajio_Sheet1");
    const worksheet2 = workbook.addWorksheet("Ajio_Sheet2");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultAJIO[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultAJIO2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultAJIO.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultAJIO2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([
      " ",
    ]);
    worksheet2.addRow([
      " ",
    ]);
    worksheet2.addRow([
      " ",
    ]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Ajio_GST_Report_${input2AJIO}.xlsx`);
  };
  const downloadExcelAJIOErrorAJIO = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorAJIOContentAJIO);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorAJIO-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };

  //////////// file convert csv to json /////////////////////
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     convertCsvToJson(file, (data) => {
  //       setJsonData(data);
  //       console.log("Converted JSON Data:", data);
  //     });
  //   }
  // };

  // const convertCsvToJson = (csvFile, callback) => {
  //   Papa.parse(csvFile, {
  //     header: true, // This tells PapaParse to use the first row as headers
  //     skipEmptyLines: true,
  //     complete: (resultAJIO) => {
  //       // resultAJIO.data will contain the JSON array
  //       callback(resultAJIO.data);
  //     },
  //     errorAJIO: (errorAJIO) => {
  //       console.errorAJIO("ErrorAJIO parsing CSV: ", errorAJIO);
  //     },
  //   });
  // };
  //////////////////////////////////////////////////

  ////////////////file upload csv to json(multiple) ///////////////////////
  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   const promises = files.map((file) => parseCsvFile(file));

  //   // Wait for all files to be parsed and then set the JSON data
  //   Promise.all(promises).then((resultAJIOs) => {
  //     setJsonData(resultAJIOs);
  //   });
  // };

  // const parseCsvFile = (file) => {
  //   return new Promise((resolve, reject) => {
  //     Papa.parse(file, {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: (resultAJIO) => {
  //         resolve({ fileName: file.name, data: resultAJIO.data });
  //       },
  //       errorAJIO: (errorAJIO) => {
  //         console.errorAJIO("ErrorAJIO parsing CSV file:", errorAJIO);
  //         reject(errorAJIO);
  //       },
  //     });
  //   });
  // };
  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultAJIO);
  console.log(data2AJIO);
  console.log(resultAJIO2);
  // console.log(jsonData);
  // console.log(newJD);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Ajio Bank Settlement</b>
        </h3>
      </div>
      {/* <div className="sub-heading">
        <h3 className="subHead"></h3>
      </div> */}

      <section class="u-section-container">
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload2AJIO}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          {/* <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2AJIO(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmitAJIO}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcelAJIO}
                  >
                    Download ResultAJIO
                  </div>
                  {errorAJIOContentAJIO.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelAJIOErrorAJIO}
                    >
                      Download ErrorAJIO Data
                    </div>
                  ) : null}
                  {errorAJIO ? (
                    <div>
                      <h3 className="errorAJIOfor<200">
                        Tax Exclusive Gross value lesser than 100
                      </h3>
                    </div>
                  ) : null}
                </div>

                {/* <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2AJIO.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2AJIO[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                       
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2AJIO.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
