import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Excel2 from "./component/excel2";
// import Login from "./Login";
// import Home from "./pages/Home";
import Myntraforward from "./calculator/Myntraforward";
import Flipkart from "./calculator/Flipkart";
import Main from "./aspfinder/flipkart/Main";
// import aspfinder from "./pages/aspfinder";
import Mynmain from "./aspfinder/myntra/Mynmain";
import Amzmain from "./aspfinder/amazon/Amzmain";
// import Amzprimemain from "./flipkartnew/Amzprimemain";
// import Amzfbamain from "./flipkartnew/Amzfbamain";
import Amazon from "./calculator/Amazon.js";
import Loginpage from "./pages/authentication/Loginpage";
import Layout from "./pages/Layout";
import Test from "./Test";
import Home from "./pages/Home";
import AddData from "./Data_adding_page/AdminPage.js";
import Myntra_ppmp from "./Rebates/Myntra_.js";
import Myntra_Opt from "./optin/Myntra_.js";
import MyntraforwardSJIT from "./calculator/myntraSJIT/MyntraforwardSJIT.js";
import MyntraSJITrebate from "./Rebates/MyntraSJIT.js";
import ContextProvider from "./Data_adding_page/ContextProvider.js";
import MyntraSJIT from "../src/aspfinder/myntraSJIT/Mynmain.js";
import MyntraSJITOptin from "../src/optin/MyntraSJIT.js";
import GSTAjio from "../src/Reports/GSTAjio.js";
import GSTAjioReturn from "../src/Reports/GSTAjioReturn.js";
import AmazonReport from "../src/Reports/Amazon.js";
import MyntraRep from "../src/Reports/MyntraRep.js";
import FlipkartRep from "../src/Reports/Flipkart.js";
import AjioBankSettlement from "../src/Bank settlement/Ajio.js";
import MyntraBankSettlement from "../src/Bank settlement/Myntra.js";
import AmazonBanksettlement from "../src/Bank settlement/Amazon.js";
import FlipkartBanksettlement from "../src/Bank settlement/Flipkart.js";
import JiomartGSTreport from "../src/Reports/JIOmart.js";
import LimeRoadGSTreport from "../src/Reports/LimeRoad.js";
import MeeshoGSTreport from "../src/Reports/Meesho.js";
import NykaaFashionsGSTreport from "../src/Reports/Nykaa_Fashions.js";
import NykaaGSTreport from "../src/Reports/Nykaa.js";
import WholeBankSettlement from "../src/Bank settlement 2/Bank_settlement_whole.js"
import "./css/appp.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aspfinderflipkart" element={<Main />} />
          <Route path="aspfindermyntra" element={<Mynmain />} />
          <Route path="aspfindereasyship" element={<Amzmain />} />
          <Route path="aspfinderprimeonly" element={<Amzmain />} />
          <Route path="aspfinderfba" element={<Amzmain />} />
          {/* 
          <Route path="/aspfinder" element={<Excel2 />} />
          
          */}
          <Route path="aspcalculator-myntra" element={<Myntraforward />} />
          <Route path="aspcalculator-flipkart" element={<Flipkart />} />
          <Route path="aspcalculator-amazon" element={<Amazon />} />

          <Route path="Flipkart-add-data" element={<AddData />} />
          <Route path="Myntra-add-data" element={<AddData />} />
          <Route path="Amazon-add-data" element={<AddData />} />
          <Route path="MyntraSJIT-add-data" element={<AddData />} />

          <Route path="RebateMyntraPPMP" element={<Myntra_ppmp />} />
          <Route path="OptinMyntraPPMP" element={<Myntra_Opt />} />
          <Route path="MyntraSJIT" element={<MyntraforwardSJIT />} />
          <Route path="MyntraSJITfinder" element={<MyntraSJIT />} />
          <Route path="RebateMyntraSJIT" element={<MyntraSJITrebate />} />
          <Route path="OptinMyntraSJIT" element={<MyntraSJITOptin />} />
          <Route path="AjioReport" element={<GSTAjio />} />
          <Route path="AjioReturnReport" element={<GSTAjioReturn />} />
          <Route path="AmazonReport" element={<AmazonReport />} />
          <Route path="MyntraReport" element={<MyntraRep />} />
          <Route path="FlipkartReport" element={<FlipkartRep />} />
          <Route path="AjioBankSettlement" element={<AjioBankSettlement />} />
          <Route path="LimeRoadGSTreport" element={<LimeRoadGSTreport />} />
          <Route path="MeeshoGSTreport" element={<MeeshoGSTreport />} />
          <Route path="NykaaFashionsGSTreport" element={<NykaaFashionsGSTreport />} />
          <Route path="NykaaGSTreport" element={<NykaaGSTreport />} />
          <Route path="WholeBankSettlement" element={<WholeBankSettlement />} />


          <Route
            path="MyntraBankSettlement"
            element={<MyntraBankSettlement />}
          />
          <Route
            path="AmazonBankSettlement"
            element={<AmazonBanksettlement />}
          />
          <Route
            path="FlipkartBankSettlement"
            element={<FlipkartBanksettlement />}
          />
            <Route
            path="jiomartGSTreport"
            element={<JiomartGSTreport />}
          />
         
          {/* <Route path="Flipkart-add-data" element={<ContextProvider />} /> */}

          <Route path="test" element={<Test />} />
        </Route>
        <Route path="login" element={<Loginpage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
