import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Outlet, Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../Data_adding_page/CounterSlice";
// import "../css/appp.css";
import "../App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = () => {
  const [logout, setLogout] = React.useState(false);
  // const { theme, setTheme } = useContext(ThemeContext);
  const [ip, SetIp] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [isHidden2, setIsHidden2] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHidden3, setIsHidden3] = useState(true);
  const [isHidden4, setIsHidden4] = useState(true);
  const [isHidden5, setIsHidden5] = useState(true);


  const dispatch = useDispatch();

  const handleToggle = () => {
    setIsHidden(!isHidden);
  };

  const handleMouseEnter = () => {
    setIsHidden(false);
  };

  const handleMouseLeave = () => {
    setIsHidden(true);
  };

  const handleMouseEnter3 = () => {
    setIsHidden3(false);
  };
  const handleMouseEnter4 = () => {
    setIsHidden4(false);
  };
  const handleMouseEnter5 = () => {
    setIsHidden5(false);
  };
  const handleMouseLeave3 = () => {
    setIsHidden3(true);
  };
  const handleMouseLeave4 = () => {
    setIsHidden4(true);
  };
  const handleMouseLeave5 = () => {
    setIsHidden5(true);
  };
  const handleToggle2 = () => {
    setIsHidden2(!isHidden2);
  };
  const handleToggle3 = () => {
    setIsHidden3(!isHidden3);
  };
  const handleToggle4 = () => {
    setIsHidden4(!isHidden4);
  };
  const handleToggle5 = () => {
    setIsHidden5(!isHidden5);
  };

  const handleMouseEnter2 = () => {
    setIsHidden2(false);
  };

  const handleMouseLeave2 = () => {
    setIsHidden2(true);
  };

  useEffect(() => {
    if (
      !localStorage.getItem("client") &&
      !localStorage.getItem("auth") &&
      !localStorage.getItem("user")
    ) {
      Navigate("/login");
    }
  }, [logout]);
  const bodyRef = useRef(document.body);
  useEffect(() => {
    bodyRef.current.className = "";
    return () => {
      bodyRef.current.className = bodyRef.current.className
        .replace("my-body-class", "")
        .replace("myntra-forward", "")
        .replace("Flipkart", "")
        .replace("Myntra-asp", "")
        .replace("Amazon-asp", "");
    };
  }, []);

  // useEffect(() => {
  //   document.body.classList.add("main-body");
  //   return () => {
  //     document.body.classList.remove("my-body-class");
  //     document.body.classList.remove("myntra-forward");
  //     document.body.classList.remove("Flipkart");
  //     document.body.classList.remove("Myntra-asp");
  //     document.body.classList.remove("Amazon-asp");
  //   };
  // }, []);
  // document.body.classList.add("main-body");

  const Navigate = useNavigate();

  const handleLogout = () => {
    Navigate("/login");
  };

  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("client") &&
  //     !localStorage.getItem("auth") &&
  //     !localStorage.getItem("user")
  //   ) {
  //     Navigate("/login");
  //   }
  // }, [logout]);

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("auth");
    localStorage.removeItem("client");
    localStorage.removeItem("user");
    setLogout(true);
  };

  // const dropdownEvent = (name) => {
  //   setTheme(name);
  // };

  // const a = (
  //   <ul class="navbar-nav">
  //     <li class="nav-item dropdown">
  //       <Link
  //         class="nav-link dropdown-toggle"
  //         to="#"
  //         role="button"
  //         data-bs-toggle="dropdown"
  //         aria-expanded="false"
  //       >
  //         Asp calculator
  //       </Link>
  //       <ul class="dropdown-menu">
  //         <li>
  //           <Link class="dropdown-item" value="hi" to="/aspcalculator-myntra">
  //             Myntra
  //           </Link>
  //         </li>
  //         <li>
  //           <Link class="dropdown-item" to="/aspcalculator-flipkart">
  //             Flipkart
  //           </Link>
  //         </li>
  //         <li>
  //           <Link class="dropdown-item" to="#">
  //             Amazon
  //           </Link>
  //         </li>
  //       </ul>
  //     </li>
  //     <li class="nav-item dropdown">
  //       <Link
  //         class="nav-link dropdown-toggle"
  //         to="#"
  //         role="button"
  //         data-bs-toggle="dropdown"
  //         aria-expanded="false"
  //       >
  //         Asp Finder
  //       </Link>
  //       <ul class="dropdown-menu">
  //         <li>
  //           <Link class="dropdown-item" to="/aspfinderflipkart">
  //             Flipkart
  //           </Link>
  //         </li>
  //         <li>
  //           <Link class="dropdown-item" to="aspfindermyntra">
  //             Myntra
  //           </Link>
  //         </li>
  //         <li>
  //           <Link
  //             class="dropdown-item"
  //             to="aspfindereasyship"
  //             onClick={() => dropdownEvent("volvo")}
  //           >
  //             Amazon - Easy Ship
  //           </Link>
  //         </li>
  //         <li>
  //           <Link
  //             class="dropdown-item"
  //             to="aspfinderprimeonly"
  //             onClick={() => dropdownEvent("saab")}
  //           >
  //             Amazon - Prime Only
  //           </Link>
  //         </li>
  //         <li>
  //           <Link
  //             class="dropdown-item"
  //             to="aspfinderfba"
  //             onClick={() => dropdownEvent("mercedes")}
  //           >
  //             Amazon - FBA
  //           </Link>
  //         </li>
  //       </ul>
  //     </li>
  //     <li class="nav-item">
  //       <Link
  //         class="nav-link-00"
  //         id="4545"
  //         to="#"
  //         style={{ "margin-left": "966px" }}
  //         onClick={logoutHandler}
  //       >
  //         Sign out
  //       </Link>
  //     </li>
  //   </ul>
  // );

  const b = (
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <Link
          class="nav-link dropdown-toggle"
          to="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Asp calculator
        </Link>
        <ul class="dropdown-menu">
          <li>
            <Link class="dropdown-item" value="hi" to="/aspcalculator-myntra">
              Myntra
            </Link>
          </li>
          <li>
            <Link class="dropdown-item" to="/aspcalculator-flipkart">
              Flipkart
            </Link>
          </li>
          <li>
            <Link class="dropdown-item" to="#">
              Amazon
            </Link>
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <Link
          class="nav-link-00"
          id="4545"
          to="#"
          style={{ "margin-left": "966px" }}
          onClick={logoutHandler}
        >
          Sign out
        </Link>
      </li>
    </ul>
  );

  return (
    // <div id="start">
    //   <nav class="navbar navbar-expand-lg bg-body-tertiary">
    //     <div class="container-fluid">
    //       <Link class="navbar-brand" to="#">
    //         Brand Fortunes
    //       </Link>
    //       <button
    //         class="navbar-toggler"
    //         type="button"
    //         data-bs-toggle="collapse"
    //         data-bs-target="#navbarNavDropdown"
    //         aria-controls="navbarNavDropdown"
    //         aria-expanded="false"
    //         aria-label="Toggle navigation"
    //       >
    //         <span class="navbar-toggler-icon"></span>
    //       </button>
    //       <div class="collapse navbar-collapse" id="navbarNavDropdown">
    //         {localStorage.getItem("client") ? b : a}
    //       </div>
    //     </div>
    //   </nav>
    // </div>
    <div class="aui-layout-row aui-top-nav-top-nav aui-layout-none">
      <div
        class="aui-layout-stack aui-top-nav-top-nav-header aui-layout-xxxl"
        style={{
          "align-items": "flex-start",
          "justify-content": "flex-start",
          "flex-wrap": "nowrap",
        }}
      >
        <div className="heading1">
          <p className="p-top-navigation-module-css-myntraLogoText">
            Brand Fortunes
          </p>
        </div>

        <div class="aui-top-nav-top-nav-header-content-container">
          <div
            class="aui-layout-stack aui-top-nav-top-nav-header-content-tabs aui-layout-small"
            style={{
              "align-items": "flex-start",
              "justify-content": "flex-start",
              "flex-wrap": "nowrap",
            }}
          >
            <Link to={"/"}>
              <button
                id="Home"
                class="aui-top-nav-top-nav-button aui-top-nav-top-nav-button-active"
              >
                Home
              </button>
            </Link>
            <button
              id="Buying_&amp;_Inventory"
              class="aui-top-nav-top-nav-button"
              onClick={handleToggle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Data Entry
              <div
                class={`aui-top-nav-hover-container ${
                  isHidden ? "hidden" : ""
                }`}
                style={{ top: "54px", left: "264px" }}
              >
                <div class="aui-top-nav-hover-item">
                  <div
                    class="aui-layout-stack aui-layout-xl"
                    style={{
                      "align-items": "flex-start",
                      "justify-content": "flex-start",
                      "flex-wrap": "nowrap;",
                    }}
                  >
                    <div>
                      <div
                        id="Order_Indent"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Rate card
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"Myntra-add-data"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra PPMP
                            </div>
                          </button>
                        </Link>
                        <Link to={"MyntraSJIT-add-data"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra SJIT
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon FBA
                          </div>
                        </button>
                        <Link to={"Amazon-add-data"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("amazon"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Amazon
                            </div>
                          </button>
                        </Link>
                        <Link to={"Flipkart-add-data"}>
                          {" "}
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("flipkart"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Flipkart
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart FBF
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-JIT
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-C+L
                          </div>
                        </button>
                      </div>
                    </div>
                    {/* <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            ASP Finder
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            Search
                          </div>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            ASP Finder
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            Search
                          </div>
                        </button>
                      </div>
                    </div> */}
                    {/* <div class="aui-layout-row aui-top-nav-hover-item-direct aui-layout-xl">
                      <button class="aui-top-nav-hover-item-direct-link">
                        Stock Transfer
                      </button>
                      <button class="aui-top-nav-hover-item-direct-link">
                        Inventory Download
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </button>
            <button
              id="Catalog"
              class="aui-top-nav-top-nav-button"
              onClick={handleToggle2}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              Pricing
              <div
                class={`aui-top-nav-hover-container aa ${
                  isHidden2 ? "hidden" : ""
                }`}
                style={{ top: "54px", left: "369px" }}
              >
                <div class="aui-top-nav-hover-item">
                  <div
                    class="aui-layout-stack aui-layout-xl"
                    style={{
                      "align-items": "flex-start",
                      "justify-content": "flex-start",
                      "flex-wrap": "nowrap;",
                    }}
                  >
                    <div>
                      <div
                        id="Order_Indent"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            ASP Finder
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"/aspfindermyntra"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra PPMP
                            </div>
                          </button>
                        </Link>
                        <Link to={"MyntraSJITfinder"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra SJIT
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon FBA
                          </div>
                        </button>
                        <Link to={"/aspfindereasyship"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Amazon
                            </div>
                          </button>
                        </Link>
                        <Link to={"/aspfinderflipkart"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Flipkart
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart FBF
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-JIT
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-C+L
                          </div>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Payment calculator
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"/aspcalculator-myntra"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra PPMP
                            </div>
                          </button>
                        </Link>
                        <Link to={"MyntraSJIT"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra SJIT
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon FBA
                          </div>
                        </button>
                        <Link to={"/aspcalculator-amazon"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Amazon
                            </div>
                          </button>
                        </Link>
                        <Link to={"/aspcalculator-flipkart"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Flipkart
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart FBF
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-JIT
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-C+L
                          </div>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Rebates
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"RebateMyntraPPMP"}>
                          <button
                            id="Search"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra PPMP
                            </div>
                          </button>
                        </Link>
                        <Link to={"RebateMyntraSJIT"}>
                          <button
                            id="Search"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra SJIT
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart
                          </div>
                        </button>
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart FBF
                          </div>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Opt-in
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"OptinMyntraPPMP"}>
                          <button
                            id="Search"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra PPMP
                            </div>
                          </button>
                        </Link>
                        <Link to={"OptinMyntraSJIT"}>
                          <button
                            id="Search"
                            class="aui-top-nav-hover-item-menu-link"
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra SJIT
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon FBA
                          </div>
                        </button>
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon
                          </div>
                        </button>
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart
                          </div>
                        </button>
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart FBF
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
            <button
              id="Buying_&amp;_Inventory"
              class="aui-top-nav-top-nav-button"
              onClick={handleToggle3}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
            >
              Reports
              <div
                class={`aui-top-nav-hover-container bb ${
                  isHidden3 ? "hidden" : ""
                }`}
                style={{ top: "54px", left: "455px" }}
              >
                <div class="aui-top-nav-hover-item">
                  <div
                    class="aui-layout-stack aui-layout-xl"
                    style={{
                      "align-items": "flex-start",
                      "justify-content": "flex-start",
                      "flex-wrap": "nowrap;",
                    }}
                  >
                    <div>
                      <div
                        id="Order_Indent"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            GST Reports
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"AjioReport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              AJIO sales report
                            </div>
                          </button>
                        </Link>
                        <Link to={"AjioReturnReport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              AJIO Return report
                            </div>
                          </button>
                        </Link>
                        <Link to={"AmazonReport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Amazon Report
                            </div>
                          </button>
                        </Link>
                        <Link to={"MyntraReport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra Report
                            </div>
                          </button>
                        </Link>
                        <Link to={"FlipkartReport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Flipkart Report
                            </div>
                          </button>
                        </Link>
                        <Link to={"jiomartGSTreport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              JIO Mart
                            </div>
                          </button>
                        </Link>
                        <Link to={"LimeRoadGSTreport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              LimeRoad
                            </div>
                          </button>
                        </Link>
                        <Link to={"MeeshoGSTreport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Meesho
                            </div>
                          </button>
                        </Link>
                        <Link to={"NykaaFashionsGSTreport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Nykaa Fashions
                            </div>
                          </button>
                        </Link>
                        <Link to={"NykaaGSTreport"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Nykaa
                            </div>
                          </button>
                        </Link>
                        {/* <Link to={"MyntraSJIT-add-data"}>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Myntra SJIT
                          </div>
                        </button></Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon FBA
                          </div>
                        </button>
                        <Link to={"Amazon-add-data"}>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                          onClick={()=>dispatch(setState("amazon"))}
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Amazon
                          </div>
                        </button></Link>
                        <Link to={"Flipkart-add-data"}>
                          {" "}
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={()=>dispatch(setState("flipkart"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Flipkart
                            </div>
                          </button>
                        </Link>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Flipkart FBF
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-JIT
                          </div>
                        </button>
                        <button
                          id="Season_Indents"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            AJIO-C+L
                          </div>
                        </button> */}
                      </div>
                    </div>
                    {/* <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            ASP Finder
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            Search
                          </div>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        id="Inventory_Approval"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            ASP Finder
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <button
                          id="Search"
                          class="aui-top-nav-hover-item-menu-link"
                        >
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap;"}}
                            >
                            Search
                          </div>
                        </button>
                      </div>
                    </div> */}
                    {/* <div class="aui-layout-row aui-top-nav-hover-item-direct aui-layout-xl">
                      <button class="aui-top-nav-hover-item-direct-link">
                        Stock Transfer
                      </button>
                      <button class="aui-top-nav-hover-item-direct-link">
                        Inventory Download
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </button>
            <button
              id="Buying_&amp;_Inventory"
              class="aui-top-nav-top-nav-button"
              onClick={handleToggle4}
              onMouseEnter={handleMouseEnter4}
              onMouseLeave={handleMouseLeave4}
            >
              Bank Settlement
              <div
                class={`aui-top-nav-hover-container bb ${
                  isHidden4 ? "hidden" : ""
                }`}
                style={{ top: "54px", left: "545px" }}
              >
                <div class="aui-top-nav-hover-item">
                  <div
                    class="aui-layout-stack aui-layout-xl"
                    style={{
                      "align-items": "flex-start",
                      "justify-content": "flex-start",
                      "flex-wrap": "nowrap;",
                    }}
                  >
                    <div>
                      <div
                        id="Order_Indent"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Bank settlement
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"AjioBankSettlement"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              AJIO
                            </div>
                          </button>
                        </Link>
                        <Link to={"MyntraBankSettlement"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Myntra
                            </div>
                          </button>
                        </Link>
                        <Link to={"AmazonBankSettlement"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Amazon
                            </div>
                          </button>
                        </Link>
                        <Link to={"FlipkartBankSettlement"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Flipkart
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
            <button
              id="Buying_&amp;_Inventory"
              class="aui-top-nav-top-nav-button"
              onClick={handleToggle5}
              onMouseEnter={handleMouseEnter5}
              onMouseLeave={handleMouseLeave5}
            >
              Bank Settlement
              <div
                class={`aui-top-nav-hover-container bb ${
                  isHidden5 ? "hidden" : ""
                }`}
                style={{ top: "54px", left: "703px" }}
              >
                <div class="aui-top-nav-hover-item">
                  <div
                    class="aui-layout-stack aui-layout-xl"
                    style={{
                      "align-items": "flex-start",
                      "justify-content": "flex-start",
                      "flex-wrap": "nowrap;",
                    }}
                  >
                    <div>
                      <div
                        id="Order_Indent"
                        class="aui-top-nav-hover-item-menu"
                      >
                        <label class="aui-top-nav-hover-item-menu-title">
                          <div
                            class="aui-layout-stack aui-layout-medium"
                            style={{
                              "align-items": "flex-start",
                              "justify-content": "flex-start",
                              "flex-wrap": "nowrap;",
                            }}
                          >
                            Bank settlement
                          </div>
                        </label>
                        <hr class="aui-top-nav-hover-item-menu-hr" />
                        <Link to={"WholeBankSettlement"}>
                          <button
                            id="Season_Indents"
                            class="aui-top-nav-hover-item-menu-link"
                            onClick={() => dispatch(setState("myntra"))}
                          >
                            <div
                              class="aui-layout-stack aui-layout-medium"
                              style={{
                                "align-items": "flex-start",
                                "justify-content": "flex-start",
                                "flex-wrap": "nowrap;",
                              }}
                            >
                              Whole Bank Settlement
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
            {/* <button
              id="Orders_&amp;_Returns"
              class="aui-top-nav-top-nav-button"
            >
              Orders &amp; Returns
            </button>
            <button
              id="Pricing,_Promo_&amp;_Ads"
              class="aui-top-nav-top-nav-button"
            >
              Pricing
            </button>
            <button id="Payment" class="aui-top-nav-top-nav-button">
              Payment
            </button>
            <button id="Business_Health" class="aui-top-nav-top-nav-button">
              Business Health
            </button>
            <button id="Reports" class="aui-top-nav-top-nav-button">
              Reports
            </button>
            <button id="Support" class="aui-top-nav-top-nav-button">
              Support
            </button> */}
          </div>
          <div className="asdf">
            <button id="001" class="001" onClick={logoutHandler}>
              Logout
            </button>
          </div>
          {/* <div
            class="aui-layout-stack aui-top-nav-top-nav-header-content-quick-links aui-layout-none"
            style={{"align-items": "flex-start", "justify-content": "flex-start", "flex-wrap": "nowrap"}}
          >
            <div class="aui-top-nav-quick-link">
              <button class="aui-top-nav-quick-link-icon-button-secondary">
                <svg
                  class="aui-icon-small aui-icon-svg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.295 13.47L12.3742 10.5492C12.3085 10.4837 12.2305 10.4319 12.1447 10.3967C12.0588 10.3615 11.9669 10.3436 11.8742 10.3442H11.3967C12.2345 9.27396 12.6885 7.95332 12.6858 6.59417C12.6861 5.79402 12.5287 5.00166 12.2226 4.26235C11.9166 3.52303 11.4679 2.85124 10.9023 2.28534C10.3366 1.71943 9.66498 1.27049 8.92579 0.964163C8.18661 0.657833 7.39432 0.50011 6.59417 0.5C5.79381 0.499781 5.00125 0.657262 4.26177 0.963445C3.52229 1.26963 2.85039 1.71851 2.28445 2.28445C1.71851 2.85039 1.26963 3.52229 0.963445 4.26177C0.657262 5.00125 0.499781 5.79381 0.5 6.59417C0.499891 7.39446 0.657452 8.18693 0.963684 8.92631C1.26992 9.66569 1.71882 10.3375 2.28475 10.9033C2.85067 11.4692 3.52254 11.918 4.26196 12.2241C5.00139 12.5303 5.79388 12.6877 6.59417 12.6875C7.95332 12.6902 9.27396 12.2362 10.3442 11.3983V11.875C10.3436 11.9677 10.3615 12.0597 10.3967 12.1455C10.4319 12.2313 10.4837 12.3093 10.5492 12.375L13.47 15.2917C13.5351 15.3572 13.6124 15.4092 13.6977 15.4446C13.7829 15.4801 13.8743 15.4984 13.9667 15.4984C14.059 15.4984 14.1504 15.4801 14.2357 15.4446C14.3209 15.4092 14.3983 15.3572 14.4633 15.2917L15.2925 14.4625C15.4233 14.3306 15.4968 14.1525 15.4973 13.9668C15.4978 13.781 15.4251 13.6026 15.295 13.47V13.47ZM6.59417 10.3442C5.59961 10.3442 4.64578 9.94908 3.94252 9.24582C3.23926 8.54256 2.84417 7.58873 2.84417 6.59417C2.84417 5.59961 3.23926 4.64578 3.94252 3.94252C4.64578 3.23926 5.59961 2.84417 6.59417 2.84417C7.58873 2.84417 8.54256 3.23926 9.24582 3.94252C9.94908 4.64578 10.3442 5.59961 10.3442 6.59417C10.3442 7.58873 9.94908 8.54256 9.24582 9.24582C8.54256 9.94908 7.58873 10.3442 6.59417 10.3442V10.3442Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </svg>
              </button>
            </div>
            <div class="aui-top-nav-quick-link">
              <button class="aui-top-nav-quick-link-icon-button">
                <svg
                  class="aui-icon-small aui-icon-svg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                    <path d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"></path>
                  </svg>
                </svg>
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
