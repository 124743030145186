import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {

  const [data2MYNTRA, setData2MYNTRA] = useState([]);
  const [errorMYNTRAContentMYNTRA, setErrorMYNTRAContentMYNTRA] = useState([]);;
  const [input2MYNTRA, setInput2MYNTRA] = useState();
  const [errorMYNTRA, setErrorMYNTRA] = useState(false);
  const [salesMYNTRA, setSalesMYNTRA] = useState([]);
  const [returnsMYNTRA, setReturnsMYNTRA] = useState([]);
  const [resultMYNTRA, setResultMYNTRA] = useState([]);
  const [resultMYNTRA2, setResultMYNTRA2] = useState([]);

  console.log(salesMYNTRA);
  console.log(returnsMYNTRA);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmitMYNTRA = (e) => {
    e.preventDefault();
    const Z = data2MYNTRA.map((item) => {
      return {
        "Payment Date": item.Payment_Date,
        "Settled Amount": item.Settled_Amount,
      };
    });

    const X = data2MYNTRA.filter((item) => {
      return item.Order_Type === "NOD";
    });
    console.log(X);
    console.log(Z);
    const finduniqueDates = (Z) => {
      // Find duplicates using filter
      const duplicateDates = Z.map((item) => item.date).filter(
        (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      // Find duplicates using filter
      console.log(Z[0]["Payment Date"]);
      const duplicates = Z.map((item) => item["Payment Date"]).filter(
        (date, index, arr) => {
          return arr.indexOf(date) !== index;
        }
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };
    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);

    let q = [];
    let o = [];
    let r = [];
    let s = [];
    duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1["Payment Date"] === item);
      q = [...q, A];
    });
    console.log(q);
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce(
        (acc, curr) => acc + curr["Settled Amount"],
        0
      );
      console.log(a2);
      const a = q[index][0]["Payment Date"];
      const g = {
        "Payment Date": a,
        "Settled Amount": a2,
      };
      o.push(g);
      setResultMYNTRA(o);
    });
    console.log(o);
    setResultMYNTRA(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1["Payment Date"] === item);
        const d = {
          "Payment Date": A[0]["Payment Date"],
          "Settled Amount": A[0]["Settled Amount"],
          // Remarks: "",
          // Amount: "",
        };
        o = [...o, d];
        console.log(o);
        setResultMYNTRA(o);
      });
    }
    console.log(resultMYNTRA);

    /////////////////////////////////
    let p = [];
    if (X.length > 0) {
      console.log(X);

      // Create a copy of the existing resultMYNTRA
      const updatedResultMYNTRA = X.map((item, index) => {
        // Check if there's a corresponding item in X for the current index
        return {
          // Spread the existing item properties
          Remarks: item.NOD_Comment, // Update Remarks
          Amount: item.Settled_Amount, // Update Amount (negated)
        };
      });
      console.log(updatedResultMYNTRA);
      // Set the new state with the updated resultMYNTRA
      setResultMYNTRA2(updatedResultMYNTRA);
    }
    console.log(p);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleFileUpload2MYNTRA = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.resultMYNTRA;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      // Convert Excel date serial numbers to formatted date strings
      const formattedData = parsedData.map((row) => {
        // Specifically convert Payment_Date if it exists
        if (row.Payment_Date && typeof row.Payment_Date === "number") {
          row.Payment_Date = excelSerialToFormattedDateMYNTRA(row.Payment_Date);
        }
        return row;
      });

      setData2MYNTRA(formattedData);
    };
  };

  // Function to convert Excel serial date to formatted date string
  function excelSerialToFormattedDateMYNTRA(serial) {
    // Handle potential Excel date serialization quirks
    if (serial < 1) return "";

    const excelEpoch = new Date(1900, 0, 1); // January is 0 in JavaScript Date

    // Adjust for Excel's leap year bug
    const adjustedSerial = serial > 59 ? serial - 1 : serial;

    const date = new Date(
      excelEpoch.getTime() + (adjustedSerial - 1) * 24 * 60 * 60 * 1000
    );

    return `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
  }

  // const handleFileUpload2MYNTRA = (e) => {
  //   // if (zx == true) {

  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.resultMYNTRA;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     // console.log(parsedData)
  //     setData2MYNTRA(parsedData);
  //     // };
  //   };
  // };

  // const downloadExcelMYNTRA = () => {
  //   const worksheet1 = XLSX.utils.json_to_sheet(resultMYNTRA);
  //   const worksheet2 = XLSX.utils.json_to_sheet(resultMYNTRA2);

  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet1, "Ajio_Sheet1");
  //   XLSX.utils.book_append_sheet(workbook, worksheet2, "Ajio_Sheet2");

  //   XLSX.writeFile(workbook, "Ajio_Bank_Settlement_Report.xlsx");
  // };

  const downloadExcelMYNTRA = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Payment Receipt Information");
    const worksheet2 = workbook.addWorksheet("Non Order Settlement");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultMYNTRA[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultMYNTRA2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultMYNTRA.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultMYNTRA2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Myntra_GST_Report_${input2MYNTRA}.xlsx`);
  };
  const downloadExcelMYNTRAErrorMYNTRA = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorMYNTRAContentMYNTRA);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorMYNTRA-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };

  //////////// file convert csv to json /////////////////////
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     convertCsvToJson(file, (data) => {
  //       setJsonData(data);
  //       console.log("Converted JSON Data:", data);
  //     });
  //   }
  // };

  // const convertCsvToJson = (csvFile, callback) => {
  //   Papa.parse(csvFile, {
  //     header: true, // This tells PapaParse to use the first row as headers
  //     skipEmptyLines: true,
  //     complete: (resultMYNTRA) => {
  //       // resultMYNTRA.data will contain the JSON array
  //       callback(resultMYNTRA.data);
  //     },
  //     errorMYNTRA: (errorMYNTRA) => {
  //       console.errorMYNTRA("ErrorMYNTRA parsing CSV: ", errorMYNTRA);
  //     },
  //   });
  // };
  //////////////////////////////////////////////////

  ////////////////file upload csv to json(multiple) ///////////////////////
  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   const promises = files.map((file) => parseCsvFile(file));

  //   // Wait for all files to be parsed and then set the JSON data
  //   Promise.all(promises).then((resultMYNTRAs) => {
  //     setJsonData(resultMYNTRAs);
  //   });
  // };

  // const parseCsvFile = (file) => {
  //   return new Promise((resolve, reject) => {
  //     Papa.parse(file, {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: (resultMYNTRA) => {
  //         resolve({ fileName: file.name, data: resultMYNTRA.data });
  //       },
  //       errorMYNTRA: (errorMYNTRA) => {
  //         console.errorMYNTRA("ErrorMYNTRA parsing CSV file:", errorMYNTRA);
  //         reject(errorMYNTRA);
  //       },
  //     });
  //   });
  // };
  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultMYNTRA);
  console.log(data2MYNTRA);
  console.log(resultMYNTRA2);
  // console.log(jsonData);
  // console.log(newJD);
  /////////////////////////////////for testing//////////////////////////////////////////////////////////////////////
  // const [status, setStatus] = useState("");

  // const handleFileUpload = async (event) => {
  //   const file = event.target.files[0];

  //   if (!file) {
  //     setStatus("No file selected");
  //     return;
  //   }

  //   try {
  //     // Read the Excel file
  //     const data = await file.arrayBuffer();
  //     const workbook = XLSX.read(data);
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];

  //     // Convert to JSON
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet);

  //     // Calculate middle index
  //     const middleIndex = Math.ceil(jsonData.length / 2);

  //     // Split data into two parts
  //     const firstHalf = jsonData.slice(0, middleIndex);
  //     const secondHalf = jsonData.slice(middleIndex);

  //     // Convert Excel date serial numbers to formatted date strings
  //     const firstHalfformattedData = firstHalf.map((row) => {
  //       // Specifically convert Payment_Date if it exists
  //       if (row.Payment_Date && typeof row.Payment_Date === "number") {
  //         row.Payment_Date = excelSerialToFormattedDateMYNTRA(row.Payment_Date);
  //       }
  //       return row;
  //     });
  //     // Convert Excel date serial numbers to formatted date strings
  //     const secondHalfformattedData = secondHalf.map((row) => {
  //       // Specifically convert Payment_Date if it exists
  //       if (row.Payment_Date && typeof row.Payment_Date === "number") {
  //         row.Payment_Date = excelSerialToFormattedDateMYNTRA(row.Payment_Date);
  //       }
  //       return row;
  //     });
  //     console.log(firstHalfformattedData);
  //     console.log(secondHalfformattedData);
  //     const spreadarray = [
  //       ...firstHalfformattedData,
  //       ...secondHalfformattedData,
  //     ];
  //     console.log("spreadarray",spreadarray);
  //     setData2MYNTRA(spreadarray);
  //     // Create new workbooks for each half
  //     const wb1 = XLSX.utils.book_new();
  //     const wb2 = XLSX.utils.book_new();

  //     // Convert JSON back to worksheets
  //     const ws1 = XLSX.utils.json_to_sheet(firstHalf);
  //     const ws2 = XLSX.utils.json_to_sheet(secondHalf);

  //     // Add worksheets to workbooks
  //     XLSX.utils.book_append_sheet(wb1, ws1, "First Half");
  //     XLSX.utils.book_append_sheet(wb2, ws2, "Second Half");

  //     // Generate file names
  //     const originalName = file.name.replace(/\.[^/.]+$/, "");
  //     const file1Name = `${originalName}_part1.xlsx`;
  //     const file2Name = `${originalName}_part2.xlsx`;

  //     // Save files
  //     // XLSX.writeFile(wb1, file1Name);
  //     // XLSX.writeFile(wb2, file2Name);

  //     console.log("first_half", firstHalf);
  //     console.log("second_half", secondHalf);

  //     setStatus("Files split successfully!");
  //   } catch (errorMYNTRA) {
  //     setStatus(`ErrorMYNTRA: ${errorMYNTRA.message}`);
  //   }
  // };

  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Myntra Bank Settlement</b>
        </h3>
      </div>
      {/* <div className="sub-heading">
        <h3 className="subHead"></h3>
      </div> */}

      <section class="u-section-container">
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload2MYNTRA}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          {/* <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2MYNTRA(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmitMYNTRA}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcelMYNTRA}
                  >
                    Download ResultMYNTRA
                  </div>
                  {errorMYNTRAContentMYNTRA.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelMYNTRAErrorMYNTRA}
                    >
                      Download ErrorMYNTRA Data
                    </div>
                  ) : null}
                  {errorMYNTRA ? (
                    <div>
                      <h3 className="errorMYNTRAfor<200">
                        Tax Exclusive Gross value lesser than 100
                      </h3>
                    </div>
                  ) : null}
                </div>

                {/* <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2MYNTRA.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2MYNTRA[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                      
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2MYNTRA.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
