import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data2, setData2] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [input0, setInput0] = useState();
  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [error, setError] = useState(false);
  const [jsonOutput, setJsonOutput] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [jsonData2, setJsonData2] = useState([]);
  const [jsonData4, setJsonData4] = useState([]);
  const [result, setResult] = useState([]);
  const [sales2, setSales2] = useState([]);

  const [jsonData3, setJsonData3] = useState([]);

  const [newJD, setNewJD] = useState([]);
  const [sales, setSales] = useState([]);
  const [returns, setReturns] = useState([]);
  const [highlight2, setHighlight2] = useState([]);
  const [highlight3, setHighlight3] = useState([]);

  console.log(sales);
  console.log(returns);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  console.log(jsonData2);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const d001 = [];

    const a001 = data2.map((item, index) => {
      const b001 = item["order_date"];
      const c001 = b001.split("-");
      const year = c001[0];
      const month = c001[1];
      const day = c001[2];

      const b002 = item["manifesttime"];
      const c002 = b002.split("-");
      const year1 = c002[0];
      const month1 = c002[1];
      const day1 = c002[2];
      return {
        "Order Date": `${day}-${month}-${year}`,
        OrderNumber: item["sub_order_num"],
        "Invoice Date": `${day1}-${month1}-${year1}`,
        hsn_code: item["hsn_code"],
        gst_amount: item["gst_amount"],
        "Invoice Total": item["meesho_price_plus_shipping_charges_total"],
        Taxable: item["tcs_taxable_amount"],
        State: item["end_customer_state"],
      };
    });
    console.log(a001);
    d001.push(...a001);

    console.log(d001);
    const e002 = d001.filter((item) => {
      return item["Invoice Date"] !== "undefined-undefined-null";
    });
    const f001 = e002.map((item, index) => {
      if (item["Taxable"] < 100 && item["Taxable"] !== 0) {
        setError(true);
        setHighlight2((prev) => [...prev, index]);
      }
    });

    //  console.log(f001);
    console.log(e002);
    setSales2(e002);
    

    /////////////////////////////////////////////////////////////////////
    const d002 = [];

    const a002 = jsonData2.map((item, index) => {
      const b001 = item["order_date"];
      const c001 = b001.split("-");
      const year = c001[0];
      const month = c001[1];
      const day = c001[2];

      const b002 = item["manifesttime"];
      const c002 = b002.split("-");
      const year1 = c002[0];
      const month1 = c002[1];
      const day1 = c002[2];
      return {
        "Order Date": `${day}-${month}-${year}`,
        "Order Number": item["sub_order_num"],
        "Invoice Date": `${day1}-${month1}-${year1}`,
        hsn_code: item["hsn_code"],
        gst_amount: item["gst_amount"],
        "Invoice Total": item["meesho_price_plus_shipping_charges_total"],
        Taxable: item["tcs_taxable_amount"],
        "Customer State": item["end_customer_state"],
      };
    });
    console.log(a002);
    d002.push(...a002);

    const e003 = d002.filter((item) => {
      return item["Invoice Date"] !== "undefined-undefined-null";
    });
    const f002 = e003.map((item, index) => {
      if (item["Taxable"] < 100 && item["Taxable"] !== 0) {
        setError(true);
        setHighlight3((prev) => [...prev, index]);
      }
    });
    console.log(d002);
    console.log(e003);
    setResult(e003);
  };
  ////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////
  //     const d002 = [];
  //     jsonData.forEach((item1) => {
  //       const a001 = item1.map((item, index) => {
  //         const b001 = item["Order Date"];
  //         const c001 = b001.split(" ");
  //         const c002 = c001[0];
  //         const c003 = c002.split("-");
  //         const day = c003[2];
  //         const month = c003[1];
  //         const year = c003[0];
  //         console.log(c002);
  //         return {
  //           "Return Shipment Number": item["Return Shipment No"],
  //           "Invoice Id": item["Invoice Number"],
  //           "Invoice Created At": `${day}-${month}-${year}`,
  //           Sku: item.SKU,
  //           Qty: item.Qty,
  //           "Item Total": item["Refund Amount"],
  //         };
  //       });
  //       console.log(a001);
  //       d002.push(...a001);
  //     });
  //     const e003 = d002.filter((item) => {
  //       return item["Invoice Id"] !== "";
  //     });
  //     console.log(e003);
  //     setResult(e003);
  //     console.log(d002);
  //   };
  ///////////////////////////////////////////////////////////////
  //   let res;
  //   let res2;
  //   let newJDA = [];
  //   let arrayOne = [];
  //   let arrayTwo = [];

  //   for (let i = 0; i < jsonData.length; i++) {
  //     arrayOne.push(jsonData[i].data);
  //   }
  //   for (let i = 0; i < jsonData2.length; i++) {
  //     arrayTwo.push(jsonData2[i].data);
  //   }

  //   let ship1;
  //   let order2;

  //   //   input.push(jsonData[i].data);
  //   // }
  //   const flatArray1 = arrayOne ? arrayOne.flat() : null;
  //   console.log(flatArray1);
  //   const flatArray2 = arrayTwo ? arrayTwo.flat() : null;
  //   console.log(flatArray2);

  //   let arrayThree = [];
  //   let filter1 = [];
  //   let filter2 = [];
  //   let filter3 = [];

  //   flatArray1.forEach((item1) => {
  //     const shipmentId = Number(item1["shipment_id"]);
  //     const orderID = Number(item1["order_id"]);
  //     if (shipmentId || orderID) {
  //       const matchingItems = flatArray2.filter(
  //         (item2) =>
  //           Number(item2["Sale_Order_Code"]) === shipmentId ||
  //           Number(item2["Sale_Order_Code"]) === orderID
  //       );

  //       const keysToRemove = [
  //         "Additional_Amount",
  //         "Article_Type",
  //         "Brand",
  //         "Cess_Amount",
  //         "Cess_Rate",
  //         "Cgst_Rate",
  //         "Coupon_Discount",
  //         "Courier_Code",
  //         "Currency",
  //         "Customer_Address",
  //         "Customer_Name",
  //         "Customer_PinCode",
  //         "Discount_Amount",
  //         "Gender",
  //         "Gift_Amount",
  //         "Igst_Rate",
  //         "Is_Try_And_Buy",
  //         "Myntra_GSTN",
  //         "Order_Code",
  //         "Order_Created_Date",
  //         "Payment_Method",
  //         "Postpaid_Amount",
  //         "Postpaid_Amount_Other",
  //         "Prepaid_Amount",
  //         "Prepaid_Amount_Other",
  //         "Product_Tax_Category",
  //         "Seller_Gstn",
  //         "Seller_Id",
  //         "Seller_Name",
  //         "Seller_Order_Id",
  //         "Seller_State_Code",
  //         "Sgst_Rate",
  //         "Shipping_Amount",
  //         "Shipping_Case",
  //         "Supply_Type",
  //         "Tax_Rate",
  //         "Tcs_Cgst_Rate",
  //         "Tcs_Igst_Rate",
  //         "Tcs_Sgst_Rate",
  //         "Tracking_no",
  //         "e_Commerce_Portal_Name",
  //       ];
  //       const dub = [...matchingItems];
  //       keysToRemove.forEach((key) => {
  //         delete dub[0][key]; // Remove the keys from the copied object
  //       });

  //       // matchingItems.forEach(matchingItem => console.log(matchingItem));
  //       filter3 = [...filter3, dub];
  //     } else {
  //       console.log("shipment_id not here");
  //     }
  //   });

  //   const R = filter3.flat();
  //   console.log(R);
  //   R.map((item, index) => {
  //     if (Number(item["Taxable_Amount"]) < 100) {
  //       setHighlight2((pre) => [...pre, index]);
  //       setError(true);
  //     }
  //   });
  //   const L = R.map((item) => {
  //     if (item.Packing_Date) {
  //       const D1 = item.Packing_Date.split(" ")[0];
  //       const Y1 = D1.split("-");
  //       const year = Y1[0];
  //       const month = Y1[1];
  //       const date = Y1[2];
  //       return { ...item, Packing_Date: `${date}-${month}-${year}` };
  //     }
  //     // const {...item,Packing_Date:_}= item
  //     //   return
  //   });

  //   setResult(L);

  //   // const As = flatArray2.filter((item) => {
  //   //   return Number(item["Sale_Order_Code"]) === 8074824430;
  //   // });

  //   // console.log(As);

  //   ////////////////////////////////////////////////////////////////////////////////
  //   // Check if Lookup has at least one element
  //   // if (Lookup.length > 0) {
  //   //   // Get the keys from the first object in the Lookup array
  //   //   const keys = Object.keys(Lookup[0]);

  //   //   // Check if input has at least one element and access shipment_id or order_id accordingly
  //   //   if (input[0] && input[1]) {
  //   //     console.log("Processing input[0]...");

  //   //     // Iterate over the first input element
  //   //     input[0].forEach((item) => {
  //   //       const newObject = {}; // Initialize newObject outside of conditionals

  //   //       keys.forEach((key) => {
  //   //         newObject[key] = null; // Initialize with null or any default value you prefer
  //   //       });

  //   //       // Check for shipment_id or order_id
  //   //       if (item.shipment_id) {
  //   //         // Ensure shipment_id exists
  //   //         console.log("item.shipment_id found");
  //   //         newObject.Sale_Order_Code = item.shipment_id; // Adjust based on your input structure
  //   //         arrayThree = [...arrayThree, newObject]; // Use spread operator to create a new array
  //   //       } else if (item.order_id) {
  //   //         // Check for order_id if shipment_id does not exist
  //   //         console.log("item.order_id found");
  //   //         newObject.Sale_Order_Code = item.order_id; // Adjust based on your input structure
  //   //         arrayThree = [...arrayThree, newObject]; // Use spread operator to create a new array
  //   //       }
  //   //     });

  //   //     input[1].forEach((item) => {
  //   //       const newObject = {}; // Initialize newObject outside of conditionals

  //   //       keys.forEach((key) => {
  //   //         newObject[key] = null; // Initialize with null or any default value you prefer
  //   //       });

  //   //       // Check for order_id or shipment_id
  //   //       // Check for order_id or shipment_id
  //   //       if (item.order_id) {
  //   //         console.log("item.order_id found");
  //   //         newObject.Sale_Order_Code = item.order_id; // Adjust based on your input structure
  //   //         arrayThree = [...arrayThree, newObject]; // Use spread operator to create a new array
  //   //       } else if (item.shipment_id) {
  //   //         // Check for shipment_id if order_id does not exist
  //   //         console.log("item.shipment_id found");
  //   //         newObject.Sale_Order_Code = item.shipment_id; // Adjust based on your input structure
  //   //         arrayThree = [...arrayThree, newObject]; // Use spread operator to create a new array
  //   //       }
  //   //     });
  //   //   }

  //   //   console.log(arrayThree);
  //   // } else {
  //   //   console.log("Lookup array is empty or input is insufficient.");
  //   // }

  //   // const updatedData2 = Array.isArray(K)
  //   //   ? K.map((item) => {
  //   //       const keysToRemove = [
  //   //         "Seller Gstin",
  //   //         // "Transaction Type",
  //   //         "Shipment Id",
  //   //         "Shipment Date",
  //   //         "Order Date",
  //   //         "Shipment Item Id",
  //   //         "Item Description",
  //   //         "Asin",
  //   //         "Product Tax Code",
  //   //         "Bill From City",
  //   //         "Bill From State",
  //   //         "Bill From Country",
  //   //         "Bill From Postal Code",
  //   //         "Ship From City",
  //   //         "Ship From State",
  //   //         "Ship From Country",
  //   //         "Ship From Postal Code",
  //   //         "Ship To City",
  //   //         "Ship To Country",
  //   //         "Ship To Postal Code",
  //   //         "Cgst Rate",
  //   //         "Sgst Rate",
  //   //         "Utgst Rate",
  //   //         "Igst Rate",
  //   //         "Compensatory Cess Rate",
  //   //         "Principal Amount",
  //   //         "Principal Amount Basis",
  //   //         "Compensatory Cess Tax",
  //   //         "Shipping Amount",
  //   //         "Shipping Amount Basis",
  //   //         "Shipping Cgst Tax",
  //   //         "Shipping Sgst Tax",
  //   //         "Shipping Utgst Tax",
  //   //         "Shipping Igst Tax",
  //   //         "Shipping Cess Tax",
  //   //         "Gift Wrap Amount",
  //   //         "Gift Wrap Amount Basis",
  //   //         "Gift Wrap Cgst Tax",
  //   //         "Gift Wrap Sgst Tax",
  //   //         "Gift Wrap Utgst Tax",
  //   //         "Gift Wrap Igst Tax",
  //   //         "Gift Wrap Compensatory Cess Tax",
  //   //         "Item Promo Discount",
  //   //         "Item Promo Discount Basis",
  //   //         "Item Promo Tax",
  //   //         "Shipping Promo Discount",
  //   //         "Shipping Promo Discount Basis",
  //   //         "Shipping Promo Tax",
  //   //         "Gift Wrap Promo Discount",
  //   //         "Gift Wrap Promo Discount Basis",
  //   //         "Gift Wrap Promo Tax",
  //   //         "Tcs Cgst Rate",
  //   //         "Tcs Cgst Amount",
  //   //         "Tcs Sgst Rate",
  //   //         "Tcs Sgst Amount",
  //   //         "Tcs Utgst Rate",
  //   //         "Tcs Utgst Amount",
  //   //         "Tcs Igst Rate",
  //   //         "Tcs Igst Amount",
  //   //         "Warehouse Id",
  //   //         "Fulfillment Channel",
  //   //         "Payment Method Code",
  //   //         "Bill To City",
  //   //         "Bill To State",
  //   //         "Bill To Country",
  //   //         "Bill To Postalcode",
  //   //         // "Customer Bill To Gstid",
  //   //         "Customer Ship To Gstid",
  //   //         "Buyer Name",
  //   //         "Credit Note No",
  //   //         "Credit Note Date",
  //   //         "Irn Number",
  //   //         "Irn Filing Status",
  //   //         "Irn Date",
  //   //         "Irn Error Code",
  //   //         "Shipping Cess Tax Amount",
  //   //       ];

  //   //       const jsonData1 = { ...item }; // Create a shallow copy of the item
  //   //       keysToRemove.forEach((key) => {
  //   //         delete jsonData1[key]; // Remove the keys from the copied object
  //   //       });
  //   //       return jsonData1; // Return the modified object
  //   //     })
  //   //   : (() => {
  //   //       console.error("data2 is not an array or is undefined.");
  //   //       return []; // Return an empty array or handle the error as needed
  //   //     })();

  //   // console.log(updatedData2);
  //   // const B = updatedData2.filter((item) => item["Invoice Number"] !== "");

  //   // const C = B.map((item) => {
  //   //   const dateString = item["Invoice Date"];
  //   //   const month0 = dateString.split(" ")[0];

  //   //   // Split the date string into components
  //   //   const [year, month, day] = month0.split("-");

  //   //   return { ...item, "Invoice Date": `${day}-${month}-${year}` };
  //   // });

  //   // console.log(C);

  //   // const R = C.filter((item) => {
  //   //   return item["Transaction Type"] !== "Refund";
  //   // });

  //   // console.log(R);
  //   // const T = R.map((item) => {
  //   //   const { "Transaction Type": _, ...rest } = item;
  //   //   return rest;
  //   // });
  //   // setSales(T);

  //   // const Y = C.filter((item) => {
  //   //   return (
  //   //     item["Transaction Type"] !== "FreeReplacement" &&
  //   //     item["Transaction Type"] !== "Shipment"
  //   //   );
  //   // });

  //   // console.log(Y);
  //   // const U = Y.map((item) => {
  //   //   const { "Transaction Type": _, ...rest } = item;
  //   //   return rest;
  //   // });
  //   // setReturns(U);

  //   // const i = T.map((item, index) => {
  //   //   if (item["Tax Exclusive Gross"] < 100) {
  //   //     setHighlight2((pre) => [...pre, index]);
  //   //     setError(true);
  //   //   }
  //   // });
  //   /////////////////////////////////////////sales/////////////////////////////////////////////////////////
  //   if (jsonData4.length > 0 && Array.isArray(jsonData4[0].data)) {
  //     let A1 = jsonData4[0].data;
  //     const B1 = A1.map((item) => {
  //       const keysToRemove = [
  //         "Additional_Amount",
  //         "Article_Type",
  //         "Brand",
  //         "Cess_Amount",
  //         "Cess_Rate",
  //         "Cgst_Rate",
  //         "Coupon_Discount",
  //         "Courier_Code",
  //         "Currency",
  //         "Customer_Address",
  //         "Customer_Name",
  //         "Customer_PinCode",
  //         "Discount_Amount",
  //         "Gender",
  //         "Gift_Amount",
  //         "Igst_Rate",
  //         "Is_Try_And_Buy",
  //         "Myntra_GSTN",
  //         "Order_Code",
  //         "Order_Created_Date",
  //         "Payment_Method",
  //         "Postpaid_Amount",
  //         "Postpaid_Amount_Other",
  //         "Prepaid_Amount",
  //         "Prepaid_Amount_Other",
  //         "Product_Tax_Category",
  //         "Seller_Gstn",
  //         "Seller_Id",
  //         "Seller_Name",
  //         "Seller_Order_Id",
  //         "Seller_State_Code",
  //         "Sgst_Rate",
  //         "Shipping_Amount",
  //         "Shipping_Case",
  //         "Supply_Type",
  //         "Tax_Rate",
  //         "Tcs_Cgst_Rate",
  //         "Tcs_Igst_Rate",
  //         "Tcs_Sgst_Rate",
  //         "Tracking_no",
  //         "e_Commerce_Portal_Name",
  //       ];
  //       const copy = { ...item };
  //       keysToRemove.forEach((key) => {
  //         delete copy[key]; // Removing the specified keys
  //       });
  //       return copy;
  //     });
  //     const L = B1.map((item) => {
  //       if (item.Packing_Date) {
  //         const D1 = item.Packing_Date.split(" ")[0];
  //         const Y1 = D1.split("-");
  //         const year = Y1[0];
  //         const month = Y1[1];
  //         const date = Y1[2];
  //         return { ...item, Packing_Date: `${date}-${month}-${year}` };
  //       }
  //       // const {...item,Packing_Date:_}= item
  //       //   return
  //     });
  //     console.log(L);
  //     console.log(B1);
  //     setSales2(L);
  //   } else {
  //     console.log("Invalid data structure or empty array");
  //   }
  // };

  const handleFileUpload2 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2(parsedData);
      // };
    };
  };
  const handleFileUpload3 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setJsonData2(parsedData);
      // };
    };
  };

  // // const downloadExcel = () => {
  // //   const worksheet1 = XLSX.utils.json_to_sheet(sales);
  // //   const worksheet2 = XLSX.utils.json_to_sheet(returns);

  // //   // console.log(worksheet)
  // //   const workbook = XLSX.utils.book_new();
  // //   // console.log(workbook)

  // //   XLSX.utils.book_append_sheet(workbook, worksheet1, "Sales");
  // //   XLSX.utils.book_append_sheet(workbook, worksheet2, "Returns");

  // //   XLSX.writeFile(workbook, "Amazon_GST_Report.xlsx");
  // // };

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("sales");
    const worksheet2 = workbook.addWorksheet("Returns");

    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(sales2[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFb0c996" }, // Yellow background
      };
    });

    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(result[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFb0c996" }, // Yellow background
      };
    });

    // Add 1 file data rows
    sales2.forEach((data, index) => {
      if (highlight2.includes(index)) {
        // Check if index is in the highlight array
        const headerRow2 = worksheet1.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Add 2 file data rows
    result.forEach((data, index) => {
      if (highlight3.includes(index)) {
        // Check if index is in the highlight array
        const headerRow2 = worksheet2.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
        worksheet2.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `JIO_mart_GST_Report_${input2}.xlsx`);
  };

  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };

  //////////// file convert csv to json /////////////////////
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     convertCsvToJson(file, (data) => {
  //       setJsonData(data);
  //       console.log("Converted JSON Data:", data);
  //     });
  //   }
  // };

  // const convertCsvToJson = (csvFile, callback) => {
  //   Papa.parse(csvFile, {
  //     header: true, // This tells PapaParse to use the first row as headers
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       // result.data will contain the JSON array
  //       callback(result.data);
  //     },
  //     error: (error) => {
  //       console.error("Error parsing CSV: ", error);
  //     },
  //   });
  // };
  //////////////////////////////////////////////////

  ////////////////file upload csv to json(multiple) ///////////////////////
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => parseCsvFile(file));

    // Wait for all files to be parsed and then set the JSON data
    Promise.all(promises).then((results) => {
      setJsonData(results);
    });
  };

  const parseCsvFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          reject(error);
        },
      });
    });
  };
  ////////////////////////////////////////////////////////////////////////////////
  const handleFileChange1 = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => parseCsvFile1(file));

    // Wait for all files to be parsed and then set the JSON data
    Promise.all(promises).then((results) => {
      setJsonData2(results);
    });
  };

  const parseCsvFile1 = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve({ fileName: file.name, data: result.data });
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          reject(error);
        },
      });
    });
  };

  const handleFileChange4 = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => parseCsvFile4(file));

    // Wait for all files to be parsed and then set the JSON data
    Promise.all(promises).then((results) => {
      setJsonData4(results);
    });
  };

  const parseCsvFile4 = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          reject(error);
        },
      });
    });
  };

  // console.log(error);
  console.log(data2);
  //console.log(jsonData);
  // console.log(newJD);
  console.log(jsonData2);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Meesho GST Reports</b>
        </h3>
      </div>
      <div className="sub-heading">
        <h3 className="subHead">Sales</h3>
      </div>

      <section class="u-section-container">
        <p className="paragraphTitle">Sales Report :</p>

        <div>
          <input type="file" accept=".csv" onChange={handleFileUpload2} />
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2(e.target.value)}
          />
        </div>
      </section>
      <div className="sub-heading">
        <h3 className="subHead">Returns</h3>
      </div>

      <section class="u-section-container">
        <p className="paragraphTitle">Returns Report :</p>

        <div>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileUpload3}
            multiple
          />
        </div>
        {/* <p className="paragraphTitle">Previous Data : </p>

        <div>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange1}
            multiple
          />
        </div> */}
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                  {error ? (
                    <div>
                      <h3 className="errorfor<200">
                        Taxable Amount value lesser than 100
                      </h3>
                    </div>
                  ) : null}
                </div>

                {/* <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                       
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
