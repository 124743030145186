import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data2, setData2] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [input0, setInput0] = useState();
  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [error,setError] = useState(false);
  console.log(forwardfeesM);
  console.log(input0, input1, input2);
  // const bodyRef = useRef(document.body);
  // useEffect(() => {
  //   bodyRef.current.className = "myntra-forward";
  //   return () => {
  //     bodyRef.current.className = bodyRef.current.className
  //       .replace("main-body", "")
  //       .replace("my-body-class", "")
  //       .replace("Amazon-asp", "")
  //       .replace("Myntra-asp", "")
  //       .replace("Flipkart", "");
  //   };
  // }, []);

  // useEffect(() => {
  //   document.body.classList.add("myntra-forward");
  //   return () => {
  //     document.body.classList.remove("main-body");
  //     document.body.classList.remove("my-body-class");
  //     document.body.classList.remove("Flipkart");
  //     document.body.classList.remove("Myntra-asp");
  //     document.body.classList.remove("Amazon-asp");
  //   };
  // }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/MCommission2");
        // console.log("Response:", response);
        setCommissionM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Mfixedfees2");
        // console.log("Response:", response);
        setFixedfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData0() {
      try {
        const response = await axios.get("/api/Mlevel2");
        // console.log("Response:", response);
        setLevelS(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData0();
  }, []);
  useEffect(() => {
    async function fetchData1() {
      try {
        const response = await axios.get("/api/Mforwardfees2");
        // console.log("Response:", response);
        setForwardfeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData1();
  }, []);
  useEffect(() => {
    async function fetchData2() {
      try {
        const response = await axios.get("/api/Mreversefees2");
        // console.log("Response:", response);
        setReversefeesM(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData2();
  }, []);

  //console.log(commissionM);
  // console.log(fixedfeesM);
  // console.log(levelS);

  // console.log(reversefeesM);
  // if (forwardfeesM.length > 0) {
  //   console.log(forwardfeesM[0]["__EMPTY_2"]);
  // }

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const updatedData2 = data2.map((item) => {
      const {
        ORDER_NO,
        CUSTOMER_INVOICE_NO,
        CUSTOMER_INVOICE_DATE,
        SELLER_ID,
        POB_ID,
        ITEM_CODE,
        DESCRIPTION,
        QUANTITY,
        CANCELLED_QUANTITY,
        TOTAL_PRICE,
        SHIPMENT_DATE,
        SHIPMENT_ID,
        LR_NUMBER,
        SELLER_STYLE_CODE,
        ...rest
      } = item; // Destructure to remove BASE_PRICE
      return rest; // Return the rest of the properties
    });
    const B = updatedData2.filter(
      (item) =>
        item.SELLER_INVOICE_NO !== "" &&
        item.SHIPPED_QUANTITY !== "0" &&
        item.SHIPPED_QUANTITY !== 0
    );
    const C = B.filter((item) => {
      const dateString = item.SELLER_INVOICE_DATE;
      const month = dateString.split(" ")[1]; // Split by spaces and get the second element
      console.log(input0); // Output: "Aug"
      return month == input0;
    });

    console.log(C);

    // const C = B.map((item)=>
    //     item.SHIPPED_QUANTITY === 0 ? 1 : item.SHIPPED_QUANTITY
    // )
    // const C = B.map(item => item.SHIPPED_QUANTITY === "0" ? "1" : item.SHIPPED_QUANTITY);
    //   const C = B.map(item => ({
    //     ...item,
    //     SHIPPED_QUANTITY: item.SHIPPED_QUANTITY === "0" && item.SHIPPED_QUANTITY === 0 ? 1 : item.SHIPPED_QUANTITY
    // }));
    // const dateString = "Thu Aug 01 09:59:22 IST 2024";
    // const month = dateString.split(" ")[1]; // Split by spaces and get the second element
    // console.log(month); // Output: "Aug"
    const E = C.map((item) => {
      // // const dateString = item.SELLER_INVOICE_DATE;
      // const dateString = "Oct 01 10:24:14 IST 2024";

      // // Create a Date object from the date string
      // const date = new Date(dateString);

      // // Format the date to "DD-MM-YYYY"
      // const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
      // const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
      // const year = date.getFullYear(); // Get full year

      // const formattedDate = `${day}-${month}-${year}`;
      // console.log(formattedDate, dateString, date); // Output: "01-10-2024"
      // return { ...item, SELLER_INVOICE_DATE: formattedDate };
      
    const dateString = item.SELLER_INVOICE_DATE;

    // Split the date string into components
    const parts = dateString.split(" ");
    const month = parts[1]; // "Oct"
    const day = parts[2]; // "01"
    const time = parts[3]; // "10:24:14"
    const year = parts[5]; // "2024"
    
    // Create a new date string in a more reliable format
    const formattedDateString = `${month} ${day} ${year} ${time}`;
    
    // Create a Date object
    const date = new Date(formattedDateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${formattedDateString}`);
    } else {
        // Format the date to "DD-MM-YYYY"
        const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
        const year = date.getFullYear(); // Get full year
    
        const formattedDate = `${day}-${month}-${year}`;
        // console.log(formattedDate); // Output: "01-10-2024"
        return { ...item, SELLER_INVOICE_DATE: formattedDate };
    }
    });

    // const dateString = "Tue Oct 01 10:24:14 IST 2024";

    // // Split the date string into components
    // const parts = dateString.split(" ");
    // const month = parts[1]; // "Oct"
    // const day = parts[2]; // "01"
    // const time = parts[3]; // "10:24:14"
    // const year = parts[5]; // "2024"
    
    // // Create a new date string in a more reliable format
    // const formattedDateString = `${month} ${day} ${year} ${time}`;
    
    // // Create a Date object
    // const date = new Date(formattedDateString);
    
    // // Check if the date is valid
    // if (isNaN(date.getTime())) {
    //     console.error(`Invalid date: ${formattedDateString}`);
    // } else {
    //     // Format the date to "DD-MM-YYYY"
    //     const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
    //     const year = date.getFullYear(); // Get full year
    
    //     const formattedDate = `${day}-${month}-${year}`;
    //     console.log(formattedDate); // Output: "01-10-2024"
    // }
  

    const D = E.map((item) => {
      const taxableValue = Number(item.TOTAL_VALUE) -
        (Number(item.CGST_AMOUNT) +
        Number(item.IGST_AMOUNT) +
        Number(item.SGST_AMOUNT));
    
      // Set error based on the taxable value
      // console.log(taxableValue);
      if (taxableValue < 100 && taxableValue !== 0 ) {
        setError(true);
      }
    
      return {
        PURCHASE_ORDER_NUMBER: item.PURCHASE_ORDER_NUMBER,
        Party_Name: "RELIANCE RETAIL LIMITED",
        GSTIN_Number: input2,
        SELLER_INVOICE_NO: item.SELLER_INVOICE_NO,
        SELLER_INVOICE_DATE: item.SELLER_INVOICE_DATE,
        BASE_PRICE: item.BASE_PRICE,
        TAXABLE_VALUE: taxableValue,
        ...item
      };
    });
    // console.log(D[0].TAXABLE_VALUE);
    const totalAmount =
      Number(B[0].CGST_AMOUNT) +
      Number(B[0].IGST_AMOUNT) +
      Number(B[0].SGST_AMOUNT);
    console.log(totalAmount);
    console.log(B);
    console.log(updatedData2);
    setData2(D);

    // console.log(commissionM.length)
    // if (commissionM.length > 0 && levelS.length > 0) {
    //   for (let i = 0; i < data2.length; i++) {
    //     for (let j = 0; j < commissionM.length; j++) {
    //       // console.log(formData.dataC, commissionM[0]["Brand"]);
    //       if (
    //         data2[i]["Vendor_Name"] === commissionM[j]["Vendor_Name"] &&
    //         data2[i]["Brand_Name"] === commissionM[j]["Brand_Name"] &&
    //         data2[i]["Gender"] === commissionM[j]["Gender"] &&
    //         data2[i]["Sub_category"] === commissionM[j]["Sub_category"]
    //       ) {
    //         console.log(commissionM[j]["Vendor_Name"]);
    //         const shippingLevel = commissionM[j]["Article_level"];
    //         console.log("shippingLevel", shippingLevel);
    //         level.push(shippingLevel);
    //         console.log("storage", level);
    //         console.log(j);
    //         const p1 = commisionfind(j);
    //         console.log("commisionfind", p1);
    //         const p3 = commissionValue(p1, i);
    //         console.log("commissionValue", p3);
    //         const p4 = fixedFeesfind(j, i);
    //         console.log("fixedFeesfind", p4);
    //         const p5 = paymentFeesfind(i);
    //         console.log("paymentFeesfind", p5);
    //         const p2 = forwardGet(j, i);
    //         console.log("forwardGet", p2);
    //         const p6 = fff(p2, i);
    //         console.log("forwardfees", p6);
    //         const p7 = reversechargesGett(i);
    //         console.log("reversechargesGett", p7);
    //         const p8 = taxget(p3, p4, p5, p6, p7);
    //         console.log("taxget", p8);
    //         const p9 = paymentReceipt(p3, p4, p5, p6, p7, p8, i);
    //         console.log("paymentReceipt", p9);
    //         setPv(p9);
    //         addPaymentReceipt(p9, i);
    //         break;
    //       } else {
    //         console.log("not match");
    //         addPaymentReceipterror("Not match", j, i);
    //       }
    //     }
    //   }
    // }
  };

  const addPaymentReceipterror = (receipt, index, i) => {
    // console.log(index);
    if (index === commissionM.length - 1) {
      setErrorContent((preview) => [...preview, data2[i]]);
      setData2((prevData) => {
        const newData = [...prevData];
        newData[i] = { ...newData[i], ["Payment Receipt"]: receipt };
        return newData;
      });
    }
  };
  const addPaymentReceipt = (receipt, index) => {
    setData2((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], ["Payment Receipt"]: receipt };
      return newData;
    });
  };
  const commisionfind = (i) => {
    return commissionM[i]["Commission_"];
    // console.log("commision",commissionPercentage)
    // setFormData({...formData, commisionF:commissionPercentage})
  };
  const commissionValue = (c, i) => {
    if (data2.length > 0) {
      return (data2[i].Asp * c) / 100;
    } else {
      return 0; // or some default value
    }
  };
  const fixedFeesfind = (indexj, index) => {
    for (let i = 0; i < fixedfeesM.length; i++) {
      if (
        data2[index]["Vendor_Name"] === fixedfeesM[i]["Vendor_Name"] &&
        data2[index]["Brand_Name"] === fixedfeesM[i]["Brand_Name"] &&
        data2[index]["Gender"] === fixedfeesM[i]["Gender"] &&
        data2[index]["Sub_category"] === fixedfeesM[i]["Sub_category"]
      ) {
        return data2[index]["Asp"] <= 500
          ? fixedfeesM[i + 1]["0-500"]
          : data2[index]["Asp"] > 500 && data2[index]["Asp"] <= 750
          ? fixedfeesM[i + 1]["500-750"]
          : data2[index]["Asp"] > 750 && data2[index]["Asp"] <= 1000
          ? fixedfeesM[i + 1]["750-1000"]
          : data2[index]["Asp"] > 1000 && data2[index]["Asp"] <= 2000
          ? fixedfeesM[i + 1]["1000-2000"]
          : data2[index]["Asp"] > 2000
          ? fixedfeesM[i + 1][">2000"]
          : null;
      } else {
        console.log("fixed fees not match");
      }
    }
    //   console.log("fixedFees",fixedFees)
    //  setFormData({...formData,fixedfeesF:fixedFees})
  };
  const paymentFeesfind = (i) => {
    return (data2[i]["Asp"] * 0.02) / (1 - data2[i]["Customer Returns"] / 100);
    // console.log("paymentFees",paymentFees)
    // setFormData({...formData, paymentFeesF:paymentFees})
  };
  const forwardGet = (i, index) => {
    return forwardd(i, index);
  };
  const fff = (s, i) => {
    return s / (1 - data2[i]["Customer Returns"] / 100);
  };
  const reversechargesGett = (i) => {
    const reversechargesget = vbnhtre(i);
    console.log("reversechargesget", reversechargesget);
    return (
      (reversechargesget * (data2[i]["Customer Returns"] / 100)) /
      (1 - data2[i]["Customer Returns"] / 100)
    );
  };

  const taxget = (p3, p4, p5, p6, p7) => {
    console.log(p3, p4, p5, p6, p7);
    return (p3 + Number(p4) + p5 + p6 + p7) * 0.18;
  };
  const paymentReceipt = (p3, p4, p5, p6, p7, p8, i) => {
    return data2[i]["Asp"] - (p3 + Number(p4) + p5 + p6 + p7 + p8);
  };

  function forwardd(i, index) {
    if (forwardfeesM.length > 0) {
      console.log(level[index]);
      if (level[index] == 1) {
        console.log("yes arrived 1");
        // return forwardfeesM[0]["__EMPTY_2"]
        return forwardfeesM[1]["PPMP1st_Unit_National"];
      }
      if (level[index] == 2) {
        console.log("yes arrived 2");

        return forwardfeesM[2]["PPMP1st_Unit_National"];
      }
      if (level[index] == 3) {
        console.log("yes arrived 3");

        return forwardfeesM[3]["PPMP1st_Unit_National"];
      }
      if (level[index] == 4) {
        console.log("yes arrived 4");

        return forwardfeesM[4]["PPMP1st_Unit_National"];
      }
      if (level[index] == 5) {
        console.log("yes arrived 5");

        return forwardfeesM[5]["PPMP1st_Unit_National"];
      }

      // if ((level[0] === 1) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[7]["column_6"];
      // }
      // if ((level[0] === 2) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[8]["column_6"];
      // }
      // if ((level[0] === 3) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[9]["column_6"];
      // }
      // if ((level[0] === 4) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[10]["column_6"];
      // }
      // if ((level[0] === 5) && (formData.dataB === "Brand Fortunes")) {
      //   return forwardfeesM[11]["column_6"];
      // }
    }
  }
  const handleClick = (e) => {
    setIsDisabled(true);
    mrp.push(e.target.value);
  };
  // function adding(e) {
  //   discount.push(e.target.value);
  // }
  function aspAdd() {
    if (isDisabled) {
      // console.log(formData.data0);
      // console.log(formData.data1);
      // console.log(formData);
      return (formData.data0 * (100 - formData.data1)) / 100;
      // console.log(formData.data0 * (100 - formData.data1) / 100)
    }
  }
  function vbnhtre(i) {
    if (level[i] == 1) {
      // return forwardfeesM[0]["__EMPTY_2"]
      return reversefeesM[1]["PPMP1st_Unit_National"];
    }
    if (level[i] == 2) {
      return reversefeesM[2]["PPMP1st_Unit_National"];
    }
    if (level[i] == 3) {
      return reversefeesM[3]["PPMP1st_Unit_National"];
    }
    if (level[i] == 4) {
      return reversefeesM[4]["PPMP1st_Unit_National"];
    }
    if (level[i] == 5) {
      return reversefeesM[5]["PPMP1st_Unit_National"];
    }

    // if ((level[0] === 1) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[6]["column_6"];
    // }
    // if ((level[0] === 2) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[7]["column_6"];
    // }
    // if ((level[0] === 3) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[8]["column_6"];
    // }
    // if ((level[0] === 4) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[9]["column_6"];
    // }
    // if ((level[0] === 5) && (formData.dataB === "Brand Fortunes")) {
    //   return reversefeesM[10]["column_6"];
    // }
  }
  function abc() {
    console.log("yesss");
    const abcd = (formData.data0 * formData.data1) / 100;
    return formData.data0 - abcd;
  }
  // const handleFileUpload2 = (e) => {
  //   // if (zx == true) {

  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     // console.log(parsedData)
  //     setData2(parsedData);
  //     // };
  //   };
  // };
  
  const handleFileUpload2 = (event) => {
    const files = event.target.files; // This is a FileList object
    const filesArray = Array.from(files); // Convert FileList to Array

    // Create an array to hold the promises for reading files
    const readFilePromises = filesArray.map((file) => {
      return new Promise((resolve, reject) => {
        console.log('File name:', file.name);
        console.log('File size:', file.size);
        console.log('File type:', file.type);
        console.log('Last modified:', file.lastModified);

        // Check for Excel file types
        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || 
            file.type === "application/vnd.ms-excel" || 
            file.name.endsWith('.xlsx') || 
            file.name.endsWith('.xls')) {
          
          const reader = new FileReader();
          
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0]; // Get the first sheet
            const worksheet = workbook.Sheets[firstSheetName]; // Get the first sheet's data
            const jsonData = XLSX.utils.sheet_to_json(worksheet); // Convert to JSON
            console.log('Parsed Excel data:', jsonData);
            resolve(jsonData); // Resolve the promise with the parsed data
          };

          reader.onerror = (e) => {
            console.error('Error reading file:', e);
            reject(e); // Reject the promise on error
          };

          // Read the file as an ArrayBuffer
          reader.readAsArrayBuffer(file);
        } else {
          console.log('Unsupported file type:', file.type);
          resolve([]); // Resolve with an empty array for unsupported file types
        }
      });
    });

    // Wait for all file read promises to resolve
    Promise.all(readFilePromises)
      .then((allData) => {
        // Flatten the array of arrays into a single array
        const combinedData = allData.flat();
        setData2(combinedData); // Set the state to the combined data
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  };
  
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data2);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Result-file.xlsx");
  };
  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };
  console.log(error);
  console.log(data2);
  console.log(commissionM);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>AJIO GST Reports</b>
        </h3>
      </div>
      <div className="sub-heading">
        <h3 className="subHead">
          Sales
        </h3>
      </div>

      <section class="u-section-container">
      <p className="paragraphTitle">Shippment Report :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload2(e); // Call the handleFileUpload2 function here
            }}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select>
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          <input
            className="inputtext"
            type="text"
            placeholder="GSTIN"
            onChange={(e) => setInput2(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                  {error ? (
                    <div>
                      <h3 className="errorfor<200">Taxable value lesser than 100</h3>
                    </div>
                  ):null}
                </div>

                <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                            {/* <th
                            rowspan="1"
                            colspan="1"
                            class=""
                            style={{"--sticky-top-offset": "calc(0 * var(--table-head-height))", "--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Documents
                          </th> */}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
