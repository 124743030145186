import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";

const Myntraforward = () => {
  const [data2AMAZON, setData2AMAZON] = useState([]);
  const [errorAMAZONContentAMAZON, setErrorAMAZONContentAMAZON] = useState([]);
  const [input2AMAZON, setInput2AMAZON] = useState();
  const [errorAMAZON, setErrorAMAZON] = useState(false);
  const [jsonDataAMAZON, setJsonDataAMAZON] = useState([]);
  const [resultAMAZON, setResultAMAZON] = useState([]);
  const [resultAMAZON2, setResultAMAZON2] = useState([]);

  const handleFormSubmitAMAZON = (e) => {
    e.preventDefault();

    const l = data2AMAZON.filter((item) => {
      return item["type"] === "Transfer";
    });
    console.log(l);

    const Z = l.map((item) => {
      // Original date string
      const dateStr = item["date/time"];

      // Create a Date object from the date string
      const dateObj = new Date(dateStr);

      // Get the day, month (0-indexed), and year
      const day = dateObj.getUTCDate();
      const month = dateObj.getUTCMonth() + 1; // Months are 0-indexed, so add 1
      const year = dateObj.getUTCFullYear();

      // Format the date as "DD-M-YYYY"
      const formattedDate = `${day}-${month}-${year}`;

      console.log(formattedDate); // Output: "30-9-2024"
      return {
        "Payment Date": formattedDate,
        "Settled Amount": Number(item.total),
      };
    });

     console.log(Z);
    // console.log(l);
    const finduniqueDates = (Z) => {
      // Find duplicates using filter
      const duplicateDates = Z.map((item) => item.date).filter(
        (date, index, arr) => arr.indexOf(date) === arr.lastIndexOf(date)
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicateDates)];
      console.log(uniqueDuplicates);
      return uniqueDuplicates;
    };
    const findDuplicateDates1 = (Z) => {
      // Store all dates
      // Find duplicates using filter
      // console.log(Z[0]["Payment Date"]);
      const duplicates = Z.map((item) => item["Payment Date"]).filter(
        (date, index, arr) => {
          return arr.indexOf(date) !== index;
        }
      );
      // Get unique duplicates
      const uniqueDuplicates = [...new Set(duplicates)];

      return uniqueDuplicates;
    };

    const duplicateDates = findDuplicateDates1(Z);
    const uniqueDates = finduniqueDates(Z);
    console.log("dublicate", duplicateDates);
    console.log("unique", uniqueDates);

    let q = [];
    let o = [];
    let r = [];
    let s = [];

    // console.log(Z[0]["Settled Amount"]);
    duplicateDates.map((item) => {
      // Filter Z to get all items with matching DOCDATE
      const A = Z.filter((item1) => item1["Payment Date"] === item);
      q = [...q, A];
    });

    console.log(q);
    //  const a =  q[1].map((item) => (isNaN(item["Settled Amount"]) ? item : null));    // console.log(q[1][0]["Settled Amount"]);
    //  console.log(a);
    // Calculate total amount
    q.map((i, index) => {
      const a2 = q[index].reduce(
        (acc, curr) => acc + curr["Settled Amount"],
        0
      );
      console.log(a2);
      const a = q[index][0]["Payment Date"];
      const g = {
        "Payment Date": a,
        "Settled Amount": a2*(-1),
      };
      o.push(g);
      setResultAMAZON(o);
    });
    console.log(o);
    setResultAMAZON(o);

    if (uniqueDates.length > 0) {
      uniqueDates.forEach((item) => {
        const A = Z.filter((item1) => item1["Payment Date"] === item);
        const d = {
          "Payment Date": A[0]["Payment Date"],
          "Settled Amount": A[0]["Settled Amount"]*(-1),
        };
        o = [...o, d];
        console.log(o);
        setResultAMAZON(o);
      });
    }
    console.log(resultAMAZON);
    console.log(o);

    /////////////////////////////////
    // let Adjustment = [];
    // let FBA_Inventory_Fee = [];
    // let Others = [];
    // let SAFET_Reimbursement = [];
    // let Reimbursements = [];
    // let Service_Fee = [];

    const Adjustment = [];
    const FBA_Inventory_Fee = [];
    const Others = [];
    const SAFET_Reimbursement = [];
    const Reimbursements = [];
    const Service_Fee = [];
    const Clawbacks = [];
    const Cancellation = [];
    data2AMAZON.forEach((item) => {
      switch (item.type) {
        case "Adjustment":
          Adjustment.push(item);
          break;
        case "FBA Inventory Fee":
          FBA_Inventory_Fee.push(item);
          break;
        case "Others":
          Others.push(item);
          break;
        case "SAFE-T Reimbursement":
          SAFET_Reimbursement.push(item);
          break;
        case "Reimbursements":
          Reimbursements.push(item);
          break;
        case "Service Fee":
          Service_Fee.push(item);
          break;
        case "Clawbacks":
          Clawbacks.push(item);
          break;
        case "Cancellation":
          Cancellation.push(item);
          break;
      }
    });

    // data2AMAZON.map((item, index) => {
    //   if (item.type === "Adjustment") {
    //     const adjustments = data2AMAZON.filter((dataItem) => {
    //       return dataItem.type === "Adjustment";
    //     });
    //     console.log("Adjustment", adjustments);
    //     Adjustment.push(...adjustments); // Spread operator to push individual items
    //   }
    //   if (item.type === "FBA Inventory Fee") {
    //     const x2 = data2AMAZON.filter((item) => {
    //       return item.type === "FBA Inventory Fee";
    //     });
    //     // console.log("FBA Inventory Fee", x2);
    //     FBA_Inventory_Fee.push(...x2);
    //   }
    //   if (item.type === "Others") {
    //     const x3 = data2AMAZON.filter((item) => {
    //       return item.type === "Others";
    //     });
    //     // console.log("Others", x3);
    //     Others.push(...x3);
    //   }
    //   if (item.type === "SAFE-T Reimbursement") {
    //     const x4 = data2AMAZON.filter((item) => {
    //       return item.type === "SAFE-T Reimbursement";
    //     });
    //     // console.log("SAFE-T Reimbursement", x4);
    //     SAFET_Reimbursement.push(...x4);
    //   }
    //   if (item.type === "Reimbursements") {
    //     const x5 = data2AMAZON.filter((item) => {
    //       return item.type === "Reimbursements";
    //     });
    //     // console.log("Reimbursements", x5);
    //     Reimbursements.push(...x5);
    //   }
    //   if (item.type === "Service Fee") {
    //     const x6 = data2AMAZON.filter((item) => {
    //       return item.type === "Service Fee";
    //     });
    //     // console.log("Service Fee", x6);
    //     Service_Fee.push(...x6);
    //   }
    // });

    console.log("Adjustment", Adjustment);
    console.log("FBA_Inventory_Fee", FBA_Inventory_Fee);
    console.log("Others", Others);
    console.log("SAFET_Reimbursement", SAFET_Reimbursement);
    console.log("Reimbursements", Reimbursements);
    console.log("Service_Fee", Service_Fee);
    console.log("clawbacks",Clawbacks);
    console.log("cancellation",Cancellation);

    let R = [];

    /////Adjustment////////////////
    if (Adjustment.length > 0) {
      const r = Adjustment.filter((item) => {
        return item.description !== "Other";
      });
      console.log(r); ///[]////
      const unique1 = [...new Set(r.map((item) => item.description))];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = r.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }
    ////////////////////FBA_Inventory_Fee///////////////////////////
    if (FBA_Inventory_Fee.length > 0) {
 
      const unique1 = [
        ...new Set(FBA_Inventory_Fee.map((item) => item.description)),
      ];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = FBA_Inventory_Fee.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        console.log(g)
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }
    ////////////Others/////////////////////
    if (Others.length > 0) {
      const r = Others.filter((item) => {
        return item.description === "TDS Reimbursement";
      });
      console.log(r); ///[]////
      const unique1 = [...new Set(r.map((item) => item.description))];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = r.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }
    /////////////////SAFET_Reimbursement//////////////////////
    if (SAFET_Reimbursement.length > 0) {
    
      const e = SAFET_Reimbursement.reduce(
        (acc, curr) => acc + Number(curr.total),
        0
      );
      console.log(e);
      const a = { Remarks: "SPF", Amount: e };
      R = [...R, a];
    }
    ////////////////////Reimbursements/////////////////////
    if (Reimbursements.length > 0) {
    
      const e = Reimbursements.reduce(
        (acc, curr) => acc + Number(curr.total),
        0
      );
      console.log(e);
      const a = { Remarks: "SPF", Amount: e };
      R = [...R, a];
    }
        ////////////////////clawbacks/////////////////////
        if (Clawbacks.length > 0) {
    
          const e = Clawbacks.reduce(
            (acc, curr) => acc + Number(curr.total),
            0
          );
          console.log(e);
          const a = { Remarks: "Clawbacks", Amount: e };
          R = [...R, a];
        }
        ////////////////////cancellation/////////////////////
        if (Cancellation.length > 0) {

          const e = Cancellation.reduce(
            (acc, curr) => acc + Number(curr.total),
            0
          );
          console.log(e);
          const a = { Remarks: "Cancellation", Amount: e };
          R = [...R, a];
        }
    //////////Service_Fee///////////////////////////////////
    if (Service_Fee.length > 0) {
      const r = Service_Fee.filter((item) => {
        return (
          item.description === "Cost of Advertising" ||
          item.description === "Order Cancellation Charge"
        );
      });
      console.log(r); ///[]////
      const unique1 = [...new Set(r.map((item) => item.description))];
      console.log(unique1); ////[]////

      const d = unique1.map((item) => {
        const h = r.filter((i) => i.description === item);
        return h;
      }); /////////////[[],[]]////////////////////
      console.log(d);
      const k = d.map((x) => {
        const g = x.reduce((acc, curr) => {
          return acc + Number(curr.total);
        }, 0);
        return { Remarks: x[0].description, Amount: g };
      });
      console.log(k);
      R = [...R, ...k];
      console.log(R);
    }

    /////////////////////////////////
    console.log(R);
    // Assuming R is an array of objects with Remarks and Amount properties
    const processData = (data) => {
      // Filter items with "SPF" remarks
      const filteredItems = data.filter((x) => x.Remarks === "SPF");
      console.log("Filtered SPF items:", filteredItems);

      // Sum up the amounts of filtered items
      const totalAmount = filteredItems.reduce(
        (acc, curr) => acc + curr.Amount,
        0
      );
      console.log("Total SPF amount:", totalAmount);

      // Create new object with the total
      const summaryObject = { Remarks: "SPF", Amount: totalAmount };
      console.log("Summary object:", summaryObject);
       const newdata= data.filter((item)=>{
          return item.Remarks !== "SPF" && item.Amount !==0
        })
      // Add the summary object to the original array
      // Using spread operator to create a new array
      const newArray = [...newdata, summaryObject];
      console.log("Final array:", newArray);

      return newArray;
    };
    const resultAMAZON_ = processData(R);
    console.log(resultAMAZON_);
    setResultAMAZON2(resultAMAZON_);
   
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //   const handleFileUpload2AMAZON = (e) => {
  //     const reader = new FileReader();
  //     reader.readAsBinaryString(e.target.files[0]);

  //     reader.onload = (e) => {
  //       const data = e.target.resultAMAZON;
  //       const workbook = XLSX.read(data, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       const parsedData = XLSX.utils.sheet_to_json(sheet);

  //       // Convert Excel date serial numbers to formatted date strings
  //       const formattedData = parsedData.map((row) => {
  //         // Specifically convert Payment_Date if it exists
  //         if (row.Payment_Date && typeof row.Payment_Date === "number") {
  //           row.Payment_Date = excelSerialToFormattedDate(row.Payment_Date);
  //         }
  //         return row;
  //       });

  //       setData2AMAZON(formattedData);
  //     };
  //   };

  // Function to convert Excel serial date to formatted date string
  // function excelSerialToFormattedDate(serial) {
  //   // Handle potential Excel date serialization quirks
  //   if (serial < 1) return "";

  //   const excelEpoch = new Date(1900, 0, 1); // January is 0 in JavaScript Date

  //   // Adjust for Excel's leap year bug
  //   const adjustedSerial = serial > 59 ? serial - 1 : serial;

  //   const date = new Date(
  //     excelEpoch.getTime() + (adjustedSerial - 1) * 24 * 60 * 60 * 1000
  //   );

  //   return `${("0" + date.getDate()).slice(-2)}-${(
  //     "0" +
  //     (date.getMonth() + 1)
  //   ).slice(-2)}-${date.getFullYear()}`;
  // }

  const handleXlsvCsvAMAZON = (event, setData) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const fileExtension = file.name.split('.').pop().toLowerCase();
  
    if (fileExtension === 'csv') {
      console.log("CSV")
      handleFileUpload2AMAZON(file, setData2AMAZON)
      // handleCsvFile(file, setData);
    } else if (['xlsx', 'xls'].includes(fileExtension)) {
      console.log("XLSV");
      handleExcelFileAMAZON(event, setData2AMAZON);
    } else {
      console.errorAMAZON('Unsupported file format. Please upload a CSV or Excel file.');
    }
  };

  const handleExcelFileAMAZON = (e,setData2AMAZON) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    // console.log(e.target.files)
    reader.onload = (e) => {
      const data = e.target.resultAMAZON;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // console.log(parsedData)
      setData2AMAZON(parsedData);
      // };
    };
  };
  const handleFileUpload2AMAZON = (file,setData2AMAZON) => {
    
    if (file) {
      convertCsvToJsonAMAZON(file, (data) => {
        setData2AMAZON(data);
        console.log("Converted JSON Data:", data);
      });
    }
  };

  const convertCsvToJsonAMAZON = (csvFile, callback) => {
    Papa.parse(csvFile, {
      header: true, // This tells PapaParse to use the first row as headers
      skipEmptyLines: true,
      complete: (resultAMAZON) => {
        // resultAMAZON.data will contain the JSON array
        callback(resultAMAZON.data);
      },
      errorAMAZON: (errorAMAZON) => {
        console.errorAMAZON("ErrorAMAZON parsing CSV: ", errorAMAZON);
      },
    });
  };
  // const handleFileUpload2AMAZON = (e) => {
  //   // if (zx == true) {

  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.resultAMAZON;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     // console.log(parsedData)
  //     setData2AMAZON(parsedData);
  //     // };
  //   };
  // };

  // const downloadExcelAMAZON = () => {
  //   const worksheet1 = XLSX.utils.json_to_sheet(resultAMAZON);
  //   const worksheet2 = XLSX.utils.json_to_sheet(resultAMAZON2);

  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet1, "Ajio_Sheet1");
  //   XLSX.utils.book_append_sheet(workbook, worksheet2, "Ajio_Sheet2");

  //   XLSX.writeFile(workbook, "Ajio_Bank_Settlement_Report.xlsx");
  // };

  const downloadExcelAMAZON = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Payment Receipt Information");
    const worksheet2 = workbook.addWorksheet("Non Order Settlement");
    // Add a row with the title "Payment Receipt Information"
    // Add a row with the title "Payment Receipt Information"
    worksheet1.addRow(["Payment Receipt Information"]);

    // Get the last row where the title was added
    const lastRow = worksheet1.getRow(worksheet1.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet1.mergeCells(worksheet1.rowCount, 1, worksheet1.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(resultAMAZON[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add a row with the title "Payment Receipt Information"
    worksheet2.addRow(["Non Order Settlement/Adjustments"]);

    // Get the last row where the title was added
    const lastRow1 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 5); // Merges from (row, startCol) to (row, endCol)
    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(resultAMAZON2[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows

    resultAMAZON.forEach((data, index) => {
      // if (highlight2.includes(index)) {
      //   // Check if index is in the highlight array
      //   const headerRow2 = worksheet1.addRow(Object.values(data));
      //   headerRow2.eachCell((cell) => {
      //     cell.font = { bold: true }; // Make header bold
      //     cell.fill = {
      //       // Set fill color for header
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // Yellow background
      //     };
      //   });
      // } else {
      worksheet1.addRow(Object.values(data)); // Add regular data row
      // }
    });

    // Add 2 file data rows
    resultAMAZON2.forEach((data, index) => {
      worksheet2.addRow(Object.values(data)); // Add regular data row
    });
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([" "]);
    worksheet2.addRow([
      "Note: Adjustments are not additional settlement values. This to notify about additional transactions that have happened for the respective month.",
    ]);

    // Get the last row where the title was added
    const lastRow2 = worksheet2.getRow(worksheet2.rowCount);

    // Merge cells from column 1 to column 5 in the last row
    worksheet2.mergeCells(worksheet2.rowCount, 1, worksheet2.rowCount, 20); // Merges from (row, startCol) to (row, endCol)

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Amazon_Bank_Settlement.xlsx`);
  };
  const downloadExcelAMAZONErrorAMAZON = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorAMAZONContentAMAZON);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ErrorAMAZON-file.xlsx");
  };
  // const downloadTemplate = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(template);
  //   // console.log(worksheet)
  //   const workbook = XLSX.utils.book_new();
  //   // console.log(workbook)

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Myntra-Template-file.xlsx");
  // };

  //////////// file convert csv to json /////////////////////
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     convertCsvToJsonAMAZON(file, (data) => {
  //       setJsonDataAMAZON(data);
  //       console.log("Converted JSON Data:", data);
  //     });
  //   }
  // };

  // const convertCsvToJsonAMAZON = (csvFile, callback) => {
  //   Papa.parse(csvFile, {
  //     header: true, // This tells PapaParse to use the first row as headers
  //     skipEmptyLines: true,
  //     complete: (resultAMAZON) => {
  //       // resultAMAZON.data will contain the JSON array
  //       callback(resultAMAZON.data);
  //     },
  //     errorAMAZON: (errorAMAZON) => {
  //       console.errorAMAZON("ErrorAMAZON parsing CSV: ", errorAMAZON);
  //     },
  //   });
  // };
  //////////////////////////////////////////////////

  ////////////////file upload csv to json(multiple) ///////////////////////
  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   const promises = files.map((file) => parseCsvFile(file));

  //   // Wait for all files to be parsed and then set the JSON data
  //   Promise.all(promises).then((resultAMAZONs) => {
  //     setJsonDataAMAZON(resultAMAZONs);
  //   });
  // };

  // const parseCsvFile = (file) => {
  //   return new Promise((resolve, reject) => {
  //     Papa.parse(file, {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: (resultAMAZON) => {
  //         resolve({ fileName: file.name, data: resultAMAZON.data });
  //       },
  //       errorAMAZON: (errorAMAZON) => {
  //         console.errorAMAZON("ErrorAMAZON parsing CSV file:", errorAMAZON);
  //         reject(errorAMAZON);
  //       },
  //     });
  //   });
  // };
  ////////////////////////////////////////////////////////////////////////////////
  console.log(resultAMAZON);
  console.log(data2AMAZON);
  // console.log(resultAMAZON2);
  // console.log(jsonDataAMAZON);
  // console.log(newJD);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Amazon Bank Settlement</b>
        </h3>
      </div>
      {/* <div className="sub-heading">
        <h3 className="subHead"></h3>
      </div> */}

      <section class="u-section-container">
        <p className="paragraphTitle">SOA file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleXlsvCsvAMAZON}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          {/* <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2AMAZON(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmitAMAZON}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcelAMAZON}
                  >
                    Download ResultAMAZON
                  </div>
                  {errorAMAZONContentAMAZON.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelAMAZONErrorAMAZON}
                    >
                      Download ErrorAMAZON Data
                    </div>
                  ) : null}
                  {errorAMAZON ? (
                    <div>
                      <h3 className="errorAMAZONfor<200">
                        Tax Exclusive Gross value lesser than 100
                      </h3>
                    </div>
                  ) : null}
                </div>

                {/* <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2AMAZON.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2AMAZON[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

               
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2AMAZON.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
