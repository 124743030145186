import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [input0, setInput0] = useState();
  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [input4, setInput4] = useState();
  const [error, setError] = useState(false);
  const [error2,setError2] = useState(false);
  const [highlight, setHighlight] = useState([]);
  const [highlight2,setHighlight2] = useState([]);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const updatedData2 = data2.map((item) => {
      const {
        ORDER_NO,
        CUSTOMER_INVOICE_NO,
        CUSTOMER_INVOICE_DATE,
        SELLER_ID,
        POB_ID,
        ITEM_CODE,
        DESCRIPTION,
        QUANTITY,
        CANCELLED_QUANTITY,
        TOTAL_PRICE,
        SHIPMENT_DATE,
        SHIPMENT_ID,
        LR_NUMBER,
        SELLER_STYLE_CODE,
        ...rest
      } = item; // Destructure to remove BASE_PRICE
      return rest; // Return the rest of the properties
    });
    const B = updatedData2.filter(
      (item) =>
        item.SELLER_INVOICE_NO !== "" &&
        item.SHIPPED_QUANTITY !== "0" &&
        item.SHIPPED_QUANTITY !== 0
    );
    const C = B.filter((item) => {
      const dateString = item.SELLER_INVOICE_DATE;
      // console.log(dateString);
      const month = dateString.split(" ")[1]; // Split by spaces and get the second element
      // console.log(month, input0);
      return month == input0;
    });
    const E = C.map((item) => {
      const dateString = item.SELLER_INVOICE_DATE;

      // Split the date string into components
      const parts = dateString.split(" ");
      const month = parts[1]; // "Oct"
      const day = parts[2]; // "01"
      const time = parts[3]; // "10:24:14"
      const year = parts[5]; // "2024"

      // Create a new date string in a more reliable format
      const formattedDateString = `${month} ${day} ${year} ${time}`;

      // Create a Date object
      const date = new Date(formattedDateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${formattedDateString}`);
      } else {
        // Format the date to "DD-MM-YYYY"
        const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
        const year = date.getFullYear(); // Get full year

        const formattedDate = `${day}-${month}-${year}`;
        return { ...item, SELLER_INVOICE_DATE: formattedDate };
      }
    });
    const D = E.map((item,index) => {
      const taxableValue =
        Number(item.TOTAL_VALUE) -
        (Number(item.CGST_AMOUNT) +
          Number(item.IGST_AMOUNT) +
          Number(item.SGST_AMOUNT));
      // console.log(index);
      // Set error based on the taxable value
      if (taxableValue < 100 && taxableValue !==0 ) {
        setError(true);
    
        setHighlight((pre) => [...pre, index]);
      
      }

      return {
        PURCHASE_ORDER_NUMBER: item.PURCHASE_ORDER_NUMBER,
        Party_Name: "RELIANCE RETAIL LIMITED",
        GSTIN_Number: input2,
        SELLER_INVOICE_NO: item.SELLER_INVOICE_NO,
        SELLER_INVOICE_DATE: item.SELLER_INVOICE_DATE,
        BASE_PRICE: item.BASE_PRICE,
        TAXABLE_VALUE: taxableValue,
        ...item,
      };
    });
    setData2(D);
    console.log(updatedData2);
    console.log(B);
    console.log(C);
    console.log(E);
    console.log(D);
  };
  useEffect(() => {
    const updatedData2 = data3.map((item) => {
      const jsonData = item;

      // List of keys to remove
      const keysToRemove = [
        "3PL Delivery Status",
        "BRAND",
        "CGST AMOUNT",
        "CGST PERCENTAGE",
        "Credit Note Acceptance date",
        "Credit Note Generation Date",
        "Credit Note Number",
        "Credit Note Posting Status",
        "Credit Note Pre Tax Value",
        "Credit Note Tax Value",
        "Credit Note Value",
        "Cust Order No",
        "Cust Return Reason",
        "DC CODE",
        "Delivery Challan Date",
        "Delivery Challan No",
        "Delivery Challan Posting Status",
        "Disposition",
        "EAN",
        "FWD AWB",
        "FWD Carrier Name",
        "FWD PO Date",
        "FWD Seller Order ID",
        "Fulfillment Type",
        "IGST AMOUNT",
        "IGST PERCENTAGE",
        "JioCode",
        "MRP",
        "POB ID",
        "QC Reason coding",
        "QC completion date",
        "RETURN ORDER NUMBER",
        "Ret Doc No",
        "Return AWB No",
        "Return Carrier Name",
        "Return Delivered Date",
        "Return Shipment ID",
        "Return Status",
        "Return Type",
        "Return Value",
        "SGST AMOUNT",
        "SGST PERCENTAGE",
        "Seller Name",
      ];

      // Remove specified keys
      keysToRemove.forEach((key) => {
        delete jsonData[key];
      });
      // console.log(jsonData);

      return jsonData;
    });
    const B = updatedData2.filter(
      (item) =>
        item["Return QTY"] !== "" &&
        item["Return QTY"] !== "0" &&
        item["FWD B2B INVOICE No"] !== ""
    );
    const E = B.map((item) => {
      const dateString = item["Return Created Date"];

      // Split the date string into components
      const parts = dateString.split(" ");
      const month = parts[1]; // "Oct"
      const day = parts[2]; // "01"
      const time = parts[3]; // "10:24:14"
      const year = parts[5]; // "2024"

      // Create a new date string in a more reliable format
      const formattedDateString = `${month} ${day} ${year} ${time}`;
console.log(formattedDateString)
      // Create a Date object
      const date = new Date(formattedDateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        // console.log(dateString);
        // console.error(`Invalid date: ${formattedDateString}`);
        return { ...item, "Return Created Date": dateString };
      } else {
        // Format the date to "DD-MM-YYYY"
        const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
        const year = date.getFullYear(); // Get full year

        const formattedDate = `${day}-${month}-${year}`;
        return { ...item, "Return Created Date": formattedDate };
      }
    });
    const R = E.map((item,index) => {
      // Assuming the date is in dd-mm-yy format
      const dateString = item["FWD B2B INVOICE Date"]; // Example: "24-10-2024"

      // Split the date string into components
      const [day, month, year] = dateString.split("-");

      // Create a new date string in the format yyyy-mm-dd
      const formattedDate = `${year}-${month}-${day}`;

      return { ...item, "FWD B2B INVOICE Date": formattedDate }; // Return the formatted date
    });

    const x = (e,u) => {
      // console.log("finddd", e);
      for (let i = 0; i < data2.length; i++) {
        if (e === data2[i].SELLER_INVOICE_NO && u === data2[i].SELLER_S_K_U) {
          // if (data2[i].TAXABLE_VALUE < r ) {
          //   console.log(i);
          //   setHighlight2((pre) => [...pre, i]);
          // }
          return {
            T: data2[i].TAXABLE_VALUE,
            C: data2[i].CGST_AMOUNT,
            S: data2[i].SGST_AMOUNT,
            I: data2[i].IGST_AMOUNT,
          };
        }
      }
      return null;
    };

    const D = R.map((item) => {
      const tx = x(item["FWD B2B INVOICE No"],item["SELLER SKU"]);
      // const tx = x(item["SELLER SKU"]);

      return {
        "SELLER SKU": item["SELLER SKU"],
        Party_Name: "RELIANCE RETAIL LIMITED",
        GSTIN_Number: input2,
        HSN: item.HSN,
        "Return Created Date": item["Return Created Date"],
        "Return QTY": item["Return QTY"],
        "FWD PO No": item["FWD PO No"],
        "FWD B2B INVOICE No": item["FWD B2B INVOICE No"],
        "FWD B2B INVOICE Date": item["FWD B2B INVOICE Date"],
        TAXABLE_VALUE: tx ? tx.T : null, // Handle case where tx might be null
        CGST_AMOUNT: tx ? tx.C : null,
        SGST_AMOUNT: tx ? tx.S : null,
        IGST_AMOUNT: tx ? tx.I : null,
        "FWD B2B Invoice Amt": item["FWD B2B Invoice Amt"],
      };
    });

    const H = D.map((item,index)=>{
        if (item.TAXABLE_VALUE > item["FWD B2B Invoice Amt"] ) {
            setHighlight2((pre) => [...pre, index]);
            setError2(true);
          }

    })

    setData3(D);
  }, [data2]);
  // const BBB = (data2) => {
  //   const updatedData2 = data3.map((item) => {
  //     const jsonData = item;

  //     // List of keys to remove
  //     const keysToRemove = [
  //       "3PL Delivery Status",
  //       "BRAND",
  //       "CGST AMOUNT",
  //       "CGST PERCENTAGE",
  //       "Credit Note Acceptance date",
  //       "Credit Note Generation Date",
  //       "Credit Note Number",
  //       "Credit Note Posting Status",
  //       "Credit Note Pre Tax Value",
  //       "Credit Note Tax Value",
  //       "Credit Note Value",
  //       "Cust Order No",
  //       "Cust Return Reason",
  //       "DC CODE",
  //       "Delivery Challan Date",
  //       "Delivery Challan No",
  //       "Delivery Challan Posting Status",
  //       "Disposition",
  //       "EAN",
  //       "FWD AWB",
  //       "FWD Carrier Name",
  //       "FWD PO Date",
  //       "FWD Seller Order ID",
  //       "Fulfillment Type",
  //       "IGST AMOUNT",
  //       "IGST PERCENTAGE",
  //       "JioCode",
  //       "MRP",
  //       "POB ID",
  //       "QC Reason coding",
  //       "QC completion date",
  //       "RETURN ORDER NUMBER",
  //       "Ret Doc No",
  //       "Return AWB No",
  //       "Return Carrier Name",
  //       "Return Delivered Date",
  //       "Return Shipment ID",
  //       "Return Status",
  //       "Return Type",
  //       "Return Value",
  //       "SGST AMOUNT",
  //       "SGST PERCENTAGE",
  //       "Seller Name",
  //     ];

  //     // Remove specified keys
  //     keysToRemove.forEach((key) => {
  //       delete jsonData[key];
  //     });
  //     console.log(jsonData);

  //     return jsonData;
  //   });
  //   const B = updatedData2.filter(
  //     (item) =>
  //       item["Return QTY"] !== "" &&
  //       item["Return QTY"] !== "0" &&
  //       item["FWD B2B INVOICE No"] !== ""
  //   );
  //   const E = B.map((item) => {
  //     const dateString = item["Return Created Date"];

  //     // Split the date string into components
  //     const parts = dateString.split(" ");
  //     const month = parts[1]; // "Oct"
  //     const day = parts[2]; // "01"
  //     const time = parts[3]; // "10:24:14"
  //     const year = parts[5]; // "2024"

  //     // Create a new date string in a more reliable format
  //     const formattedDateString = `${month} ${day} ${year} ${time}`;

  //     // Create a Date object
  //     const date = new Date(formattedDateString);

  //     // Check if the date is valid
  //     if (isNaN(date.getTime())) {
  //       console.error(`Invalid date: ${formattedDateString}`);
  //     } else {
  //       // Format the date to "DD-MM-YYYY"
  //       const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
  //       const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
  //       const year = date.getFullYear(); // Get full year

  //       const formattedDate = `${day}-${month}-${year}`;
  //       return { ...item, "Return Created Date": formattedDate };
  //     }
  //   });
  //   const R = E.map((item) => {
  //     // Assuming the date is in dd-mm-yy format
  //     const dateString = item["FWD B2B INVOICE Date"]; // Example: "24-10-2024"

  //     // Split the date string into components
  //     const [day, month, year] = dateString.split("-");

  //     // Create a new date string in the format yyyy-mm-dd
  //     const formattedDate = `${year}-${month}-${day}`;

  //     return { ...item, "FWD B2B INVOICE Date": formattedDate }; // Return the formatted date
  //   });

  //   const x = (e) => {
  //     console.log("finddd",e)
  //     for (let i = 0; i < data2.length; i++) {
  //       if (e === data2[i].SELLER_INVOICE_NO) {
  //         console.log(data2[i]);
  //         return {
  //           T: data2[i].TAXABLE_VALUE,
  //           C: data2[i].CGST_AMOUNT,
  //           S: data2[i].SGST_AMOUNT,
  //           I: data2[i].IGST_AMOUNT,
  //         };
  //       }
  //     }
  //     return null;
  //   };

  //   const D = R.map((item) => {
  //     const tx = x(item["FWD B2B INVOICE No"]);

  //     return {
  //       "SELLER SKU": item["SELLER SKU"],
  //       Party_Name: "RELIANCE RETAIL LIMITED",
  //       GSTIN_Number: input2,
  //       HSN: item.HSN,
  //       "Return Created Date": item["Return Created Date"],
  //       "Return QTY": item["Return QTY"],
  //       "FWD PO No": item["FWD PO No"],
  //       "FWD B2B INVOICE No": item["FWD B2B INVOICE No"],
  //       "FWD B2B INVOICE Date": item["FWD B2B INVOICE Date"],
  //       TAXABLE_VALUE: tx ? tx.T : null, // Handle case where tx might be null
  //       CGST_AMOUNT: tx ? tx.C : null,
  //       SGST_AMOUNT: tx ? tx.S : null,
  //       IGST_AMOUNT: tx ? tx.I : null,
  //       "FWD B2B Invoice Amt": item["FWD B2B Invoice Amt"],
  //     };
  //   });

  //   setData3(D);
  // };

  const handleFileUpload2 = (event) => {
    const files = event.target.files; // This is a FileList object
    const filesArray = Array.from(files); // Convert FileList to Array

    // Create an array to hold the promises for reading files
    const readFilePromises = filesArray.map((file) => {
      return new Promise((resolve, reject) => {
        console.log("File name:", file.name);
        console.log("File size:", file.size);
        console.log("File type:", file.type);
        console.log("Last modified:", file.lastModified);

        // Check for Excel file types
        if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel" ||
          file.name.endsWith(".xlsx") ||
          file.name.endsWith(".xls")
        ) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0]; // Get the first sheet
            const worksheet = workbook.Sheets[firstSheetName]; // Get the first sheet's data
            const jsonData = XLSX.utils.sheet_to_json(worksheet); // Convert to JSON
            console.log("Parsed Excel data:", jsonData);
            resolve(jsonData); // Resolve the promise with the parsed data
          };

          reader.onerror = (e) => {
            console.error("Error reading file:", e);
            reject(e); // Reject the promise on error
          };

          // Read the file as an ArrayBuffer
          reader.readAsArrayBuffer(file);
        } else {
          console.log("Unsupported file type:", file.type);
          resolve([]); // Resolve with an empty array for unsupported file types
        }
      });
    });

    // Wait for all file read promises to resolve
    Promise.all(readFilePromises)
      .then((allData) => {
        // Flatten the array of arrays into a single array
        const combinedData = allData.flat();
        setData2((prevData) => [...prevData, ...combinedData]); // Combine existing data with new data
      })
      .catch((error) => {
        console.error("Error processing files:", error);
      });
  };

  const handleFileUpload1 = (event) => {
    const files = event.target.files; // This is a FileList object
    const filesArray = Array.from(files); // Convert FileList to Array

    // Create an array to hold the promises for reading files
    const readFilePromises = filesArray.map((file) => {
      return new Promise((resolve, reject) => {
        console.log("File name:", file.name);
        console.log("File size:", file.size);
        console.log("File type:", file.type);
        console.log("Last modified:", file.lastModified);

        // Check for Excel file types
        if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel" ||
          file.name.endsWith(".xlsx") ||
          file.name.endsWith(".xls")
        ) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0]; // Get the first sheet
            const worksheet = workbook.Sheets[firstSheetName]; // Get the first sheet's data
            const jsonData = XLSX.utils.sheet_to_json(worksheet); // Convert to JSON
            console.log("Parsed Excel data:", jsonData);
            resolve(jsonData); // Resolve the promise with the parsed data
          };

          reader.onerror = (e) => {
            console.error("Error reading file:", e);
            reject(e); // Reject the promise on error
          };

          // Read the file as an ArrayBuffer
          reader.readAsArrayBuffer(file);
        } else {
          console.log("Unsupported file type:", file.type);
          resolve([]); // Resolve with an empty array for unsupported file types
        }
      });
    });

    // Wait for all file read promises to resolve
    Promise.all(readFilePromises)
      .then((allData) => {
        // Flatten the array of arrays into a single array
        const combinedData = allData.flat();
        setData2(combinedData); // Set the state to the combined data
      })
      .catch((error) => {
        console.error("Error processing files:", error);
      });
  };
  // const handleFileUpload2 = (e) => {
  //   // if (zx == true) {

  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     // console.log(parsedData)
  //     setData2(parsedData);
  //     // };
  //   };
  // };
  const handleFileUpload3 = (e) => {
    // if (zx == true) {

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData3(parsedData);
    };
  };

  // const downloadExcel = () => {
  //     // Create worksheets from the data
  //     const worksheet1 = XLSX.utils.json_to_sheet(data2);
  //     const worksheet2 = XLSX.utils.json_to_sheet(data3);

  //     // Apply styles to worksheet1
  //     const rowCount1 = worksheet1['!ref'].split(':')[1].replace(/[0-9]/g, ''); // Get the last row
  //     for (let row = 1; row <= rowCount1; row++) {
  //         const cellAddress = `A${row}`; // Change 'A' to the column you want to apply color to
  //         if (worksheet1[cellAddress]) {
  //             worksheet1[cellAddress].s = {
  //                 fill: {
  //                     fgColor: { rgb: "FF0000" } // Change to your desired color (e.g., red)
  //                 }
  //             };
  //         }
  //     }

  //     // Apply styles to worksheet2
  //     const rowCount2 = worksheet2['!ref'].split(':')[1].replace(/[0-9]/g, ''); // Get the last row
  //     for (let row = 1; row <= rowCount2; row++) {
  //         const cellAddress = `A${row}`; // Change 'A' to the column you want to apply color to
  //         if (worksheet2[cellAddress]) {
  //             worksheet2[cellAddress].s = {
  //                 fill: {
  //                     fgColor: { rgb: "00FF00" } // Change to your desired color (e.g., green)
  //                 }
  //             };
  //         }
  //     }

  //     // Create a new workbook
  //     const workbook = XLSX.utils.book_new();

  //     // Append the worksheets to the workbook
  //     XLSX.utils.book_append_sheet(workbook, worksheet1, "Sales");
  //     XLSX.utils.book_append_sheet(workbook, worksheet2, "Returns");

  //     // Write the workbook to a file
  //     XLSX.writeFile(workbook, `${input4} Ajio_GST_Report.xlsx`);
  // };

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("sales");
    const worksheet2 = workbook.addWorksheet("Returns");

    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(data2[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(data3[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      };
    });

    // Add 1 file data rows
    data2.forEach((data, index) => {
      console.log(index);
      console.log(highlight);
      if (highlight.includes(index)) { // Check if index is in the highlight array
        const headerRow2 = worksheet1.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
        worksheet1.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Add 2 file data rows
    data3.forEach((data, index) => {
      console.log(index);
      console.log(highlight2);
      if (highlight2.includes(index)) { // Check if index is in the highlight array
        const headerRow2 = worksheet2.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
        worksheet2.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${input4} Ajio_GST_Report.xlsx`);
  };

  const downloadExcel44 = () => {
    const worksheet = XLSX.utils.json_to_sheet(data2);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Result-file.xlsx");
  };
  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };

  // const ggg =(e)=>{
  //   e.preventDefault();
  //   setInput0(e.target.value);
  //   const updatedData2 = data2.map((item) => {
  //     const {
  //       ORDER_NO,
  //       CUSTOMER_INVOICE_NO,
  //       CUSTOMER_INVOICE_DATE,
  //       SELLER_ID,
  //       POB_ID,
  //       ITEM_CODE,
  //       DESCRIPTION,
  //       QUANTITY,
  //       CANCELLED_QUANTITY,
  //       TOTAL_PRICE,
  //       SHIPMENT_DATE,
  //       SHIPMENT_ID,
  //       LR_NUMBER,
  //       SELLER_STYLE_CODE,
  //       ...rest
  //     } = item; // Destructure to remove BASE_PRICE
  //     return rest; // Return the rest of the properties
  //   });
  //   const B = updatedData2.filter(
  //     (item) =>
  //       item.SELLER_INVOICE_NO !== "" &&
  //       item.SHIPPED_QUANTITY !== "0" &&
  //       item.SHIPPED_QUANTITY !== 0
  //   );
  //   const C = B.filter((item) => {
  //     const dateString = item.SELLER_INVOICE_DATE;
  //     const month = dateString.split(" ")[1]; // Split by spaces and get the second element
  //     console.log(month,input0);
  //     return month == input0;
  //   });
  //   const E = C.map((item) => {

  //     const dateString = item.SELLER_INVOICE_DATE;

  //     // Split the date string into components
  //     const parts = dateString.split(" ");
  //     const month = parts[1]; // "Oct"
  //     const day = parts[2]; // "01"
  //     const time = parts[3]; // "10:24:14"
  //     const year = parts[5]; // "2024"

  //     // Create a new date string in a more reliable format
  //     const formattedDateString = `${month} ${day} ${year} ${time}`;

  //     // Create a Date object
  //     const date = new Date(formattedDateString);

  //     // Check if the date is valid
  //     if (isNaN(date.getTime())) {
  //       console.error(`Invalid date: ${formattedDateString}`);
  //     } else {
  //       // Format the date to "DD-MM-YYYY"
  //       const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
  //       const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
  //       const year = date.getFullYear(); // Get full year

  //       const formattedDate = `${day}-${month}-${year}`;
  //       return { ...item, SELLER_INVOICE_DATE: formattedDate };
  //     }
  //   });
  //   const D = E.map((item) => {
  //     const taxableValue =
  //       Number(item.TOTAL_VALUE) -
  //       (Number(item.CGST_AMOUNT) +
  //         Number(item.IGST_AMOUNT) +
  //         Number(item.SGST_AMOUNT));

  //     // Set error based on the taxable value
  //     if (taxableValue < 100) {
  //       setError(true);
  //     }

  //     return {
  //       PURCHASE_ORDER_NUMBER: item.PURCHASE_ORDER_NUMBER,
  //       Party_Name: "RELIANCE RETAIL LIMITED",
  //       GSTIN_Number: input2,
  //       SELLER_INVOICE_NO: item.SELLER_INVOICE_NO,
  //       SELLER_INVOICE_DATE: item.SELLER_INVOICE_DATE,
  //       BASE_PRICE: item.BASE_PRICE,
  //       TAXABLE_VALUE: taxableValue,
  //       ...item,
  //     };
  //   });

  //   setData2(D);
  //   console.log(updatedData2)
  //   console.log(B);
  //   console.log(C)
  //   console.log(E)
  //   console.log(D)

  // }

  console.log(data1);
  console.log(data2);
  console.log(data3);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>AJIO GST Reports</b>
        </h3>
      </div>
      <div className="sub-heading">
        <h3 className="subHead">
          Sales
        </h3>
      </div>

      <section class="u-section-container">
        <p className="paragraphTitle">Shippment Report :</p>
        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload1(e); // Call the handleFileUpload2 function here
            }}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            {/* <select id="pet-select" onClick={(e)=>ggg(e)}> */}

            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select>
          <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          />
          <input
            className="inputtext"
            type="text"
            placeholder="GSTIN"
            onChange={(e) => setInput2(e.target.value)}
          />
          <input
            className="inputtext"
            type="text"
            placeholder="Brand"
            onChange={(e) => setInput4(e.target.value)}
          />
        </div>
      </section>
      <div className="sub-heading">
        <h3 className="subHead">
          Returns
        </h3>
      </div>

      <section class="u-section-container">
      <p className="paragraphTitle">RTV Report :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload3(e); // Call the handleFileUpload2 function here
            }}
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        <p className="paragraphTitle">Previous Data :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileUpload2(e); // Call the handleFileUpload2 function here
            }}
            multiple
          />
          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          {/* <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="GSTIN"
            onChange={(e) => setInput2(e.target.value)}
          /> */}
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {error ? (
                    <div>
                      <h3 className="errorfor<200">Taxable value lesser than 100</h3>
                    </div>
                  ):null}
                     {error2 ? (
                    <div>
                      <h3 className="errorfor<200">Taxable value greater than FWD B2B Invoice Amt</h3>
                    </div>
                  ):null}
                  {/* <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel44}
                  >
                    Download Shipped Result
                  </div> */}
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                </div>

     
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
